import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { AdaptiveForm } from '@cochlear-design-system/features.adaptiveForms';
import {
  Container,
  Row,
  Col,
  PageHeader,
  Alert,
  Modal,
  Button,
  Text,
} from '@cochlear-design-system/foundation';

export const DeviceRegistrationReviewWrapper = forwardRef(
  ({ config, labels, data, alertConfig, modalConfig }, ref) => {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    //  const { config, labels, data, alertConfig, modalConfigData } = deviceReviewDetails;
    useImperativeHandle(ref, () => ({
      setModalState(state) {
        setShowModal(state);
      },
    }));

    const patientName = data.adaptiveForms.personal.patientName.value;
    const patientNameFormatted = patientName
      ? patientName.toUpperCase()
      : '';

    // Determine the title based on config.activeStep
    const title =
      config.activeStep === 1
        ? 'Equipment registration submitted'
        : 'Review and register';

    const renderHeader = () => {
      return (
        <Modal.Header
          closeButton
          data-analytics="cancel_header_exit_register_device"
        >
          <Modal.Title>{modalConfig.modaltitle}</Modal.Title>
        </Modal.Header>
      );
    };

    const renderFooter = () => {
      return (
        <Modal.Footer>
          <Button
            text={modalConfig.yes}
            variant="brand-primary"
            onClick={() => modalConfig.onSuccess()}
            data-analytics={modalConfig.yesanaytics}
          />
          <Button
            text={modalConfig.no}
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
            data-analytics={modalConfig.noanaytics}
          />
        </Modal.Footer>
      );
    };

    return (
      <div className="ccl__register-review">
        <Container>
          <Row>
            <Col>
              <h6 className="device-registration__user-name">
                {patientNameFormatted}
              </h6>
              <PageHeader
                className="device-registration__title"
                title={{ content: title }} // pass the dynamic title here
              />
            </Col>
          </Row>
          {config.activeStep === 1 && (
            <Row>
              <Col xs={12}>
                <Alert
                  show
                  variant="success"
                  actions={alertConfig.actions}
                >
                  <b>{alertConfig.title}</b>
                </Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <AdaptiveForm
                labels={labels}
                config={config}
                data={data}
              />
            </Col>
          </Row>
        </Container>
        {modalConfig && (
          <Modal
            {...modalConfig}
            onHide={handleClose}
            size={modalConfig.size}
            dialogClassName="device-registration-close"
            show={showModal}
          >
            {renderHeader()}

            <Modal.Body>
              <p />
              <Text
                className="ccl__no-results__isClinicPopup__subtitle"
                content={modalConfig.modalText}
                isHTML="true"
                type="small-body-text"
              />
            </Modal.Body>

            {renderFooter()}
          </Modal>
        )}
      </div>
    );
  },
);
