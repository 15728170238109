import { getFindClinicModalConfig } from '../../cdsConfigs/findClinicModalConfig';
import { logger } from '../../logger';

const log = logger();

export const getConfig = (
  handleSearchClinic,
  handleSelectClinic,
  handleCreatePatient,
  handleContactCustomerService,
  handleViewPatientRecord,
  handleAgreementModalClose,
  handleAgreementModalSubmit,
  userCountry,
  dateInputFormat,
  dateHint,
  labels,
) => {
  const clinicSelectorConfig = getFindClinicModalConfig(
    userCountry,
    labels,
  );
  clinicSelectorConfig.searchButton.onClick = handleSearchClinic;
  clinicSelectorConfig.tableConfig.events.onRowClick =
    handleSelectClinic;
  clinicSelectorConfig.clinicField.minLength = 3;
  clinicSelectorConfig.results.maxCount = 20;

  return {
    pageTitle: 'labels.advancedSearch.pageTitle',

    onChangeTab: (tabInfo, ...rest) => {
      // eslint-disable-next-line no-console
      log.debug(tabInfo, ...rest);
    },
    formats: {
      date: dateInputFormat,
    },
    tabs: [
      {
        id: 'patients',
        title: 'labels.advancedSearch.tabs.patient.header',
        patientIdentifierLabel:
          'labels.advancedSearch.tabs.patient.patientIdentifierLabel',

        alertMessage:
          'labels.advancedSearch.tabs.patient.alertMessage',
        hasError: false,
        errorConfig: {
          imgAlt:
            'labels.advancedSearch.tabs.patient.errors.errorView.imgAlt',
          imgSrc:
            'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
          imgWidth: 116,
          text: 'labels.advancedSearch.tabs.patient.errors.errorView.text',
          title:
            'labels.advancedSearch.tabs.patient.errors.errorView.title',
          layout: 'horizontal',
        },
        duplicate: {
          heading:
            'labels.advancedSearch.tabs.patient.duplicate.heading',
          text: 'labels.advancedSearch.tabs.patient.duplicate.text',
          message:
            'labels.advancedSearch.tabs.patient.duplicate.message',
          actionLink1: handleContactCustomerService,
        },
        patientIdentifier: [
          {
            label:
              'labels.advancedSearch.tabs.patient.patientIdentifier.deviceSerial',
            value: 'device-serial',
          },
          {
            label:
              'labels.advancedSearch.tabs.patient.patientIdentifier.accountUsername',
            value: 'account-username',
          },
          {
            label:
              'labels.advancedSearch.tabs.patient.patientIdentifier.associatedClinic',
            value: 'associated-clinic',
          },
        ],
        fields: [
          {
            id: 'firstName',
            label:
              'labels.advancedSearch.tabs.patient.fields.firstName.label',
            hint: 'labels.common.optional',
            placeholder:
              'labels.advancedSearch.tabs.patient.fields.firstName.placeholder',
            validators: [
              {
                type: 'min',
                value: 1,
                message:
                  'labels.advancedSearch.tabs.patient.errors.firstName.required',
              },
              {
                type: 'max',
                value: 180,
                message:
                  'labels.advancedSearch.tabs.patient.errors.firstName.maxChars',
              },
              // {
              //   type: 'matches',
              //   value: /^[a-zA-ZÀ-ȕ\d'\- *%]+$/g,
              //   message:
              //     'labels.advancedSearch.tabs.patient.errors.firstName.specialChars',
              // },
              // {
              //   type: 'matches',
              //   value: /^\*?%?[a-zA-ZÀ-ȕ\d'\- ]+\*?%?[a-zA-ZÀ-ȕ\d'\- ]?$/g,
              //   message:
              //     'labels.advancedSearch.tabs.patient.errors.firstName.required',
              // },
              {
                type: 'required',
                message:
                  'labels.advancedSearch.tabs.patient.errors.firstName.required',
              },
            ],
          },
          {
            id: 'lastName',
            label:
              'labels.advancedSearch.tabs.patient.fields.lastName.label',
            hint: 'labels.common.optional',
            placeholder:
              'labels.advancedSearch.tabs.patient.fields.lastName.placeholder',
            validators: [
              {
                type: 'min',
                value: 1,
                message:
                  'labels.advancedSearch.tabs.patient.errors.lastName.required',
              },
              {
                type: 'max',
                value: 180,
                message:
                  'labels.advancedSearch.tabs.patient.errors.lastName.maxChars',
              },
              // {
              //   type: 'matches',
              //   value: /^[a-zA-ZÀ-ȕ\d'\- *%]+$/g,
              //   message:
              //     'labels.advancedSearch.tabs.patient.errors.lastName.specialChars',
              // },
              // {
              //   type: 'matches',
              //   value: /^\*?%?[a-zA-ZÀ-ȕ\d'\- ]+\*?%?[a-zA-ZÀ-ȕ\d'\- ]?$/g,
              //   message:
              //     'labels.advancedSearch.tabs.patient.errors.lastName.required',
              // },
              {
                type: 'required',
                message:
                  'labels.advancedSearch.tabs.patient.errors.lastName.required',
              },
            ],
          },
          {
            id: 'dateOfBirth',
            label:
              'labels.advancedSearch.tabs.patient.fields.dateOfBirth.label',
            hint: dateHint,
            fieldLabels: {
              d: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.dayLabel',
              m: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.monthLabel',
              y: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.yearLabel',
            },
            validators: [
              {
                type: 'required',
                message:
                  'labels.advancedSearch.tabs.patient.fields.dateOfBirth.validators.required',
              },
            ],
          },
          {
            id: 'deviceSerial',
            label:
              'labels.advancedSearch.tabs.patient.fields.deviceSerial.label',
            hint: 'labels.advancedSearch.tabs.patient.fields.deviceSerial.hint',
            validators: [
              {
                type: 'min',
                value: 5,
                message:
                  'labels.advancedSearch.tabs.patient.errors.deviceSerial.minLength',
              },
              // {
              //   type: 'max',
              //   value: 13,
              //   message: '',
              // },
              {
                type: 'required',
                message:
                  'labels.advancedSearch.tabs.patient.errors.deviceSerial.required',
              },
            ],
          },
          {
            id: 'accountUsername',
            label:
              'labels.advancedSearch.tabs.patient.fields.accountUsername.label',
            hint: 'labels.advancedSearch.tabs.patient.fields.accountUsername.hint',
            validators: [
              {
                type: 'matches',
                value:
                  /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
                message:
                  'labels.advancedSearch.tabs.patient.errors.accountUsername.validEmail',
              },
              {
                type: 'required',
                message:
                  'labels.advancedSearch.tabs.patient.errors.accountUsername.required',
              },
            ],
          },
          {
            id: 'associatedClinic',
            label:
              'labels.advancedSearch.tabs.patient.fields.associatedClinic.label',
            changeLabel:
              'labels.advancedSearch.tabs.patient.fields.associatedClinic.changeLabel',
            validators: [
              {
                type: 'required',
                message: '',
              },
            ],
          },
        ],

        patientResultTableConfig: {
          country: 'US',
          language: 'en',
          formats: {
            date: 'yyyy-mm-dd',
            currency: 'USD',
          },
          highlight: false,
          hover: true,
          mode: '',
          loadingText: 'labels.common.loadingText',
          resultsHeadingFormat:
            'labels.advancedSearch.tabs.patient.table.resultsHeading',
          resultsLimit: {
            maxResults: 100,
            content: 'labels.dataTable.resultsLimit.content',
            additional: {
              textOrder:
                'labels.dataTable.resultsLimit.additional.textOrder',
              text1: 'labels.dataTable.resultsLimit.additional.text1',
              text2: 'labels.dataTable.resultsLimit.additional.text2',
              linkText1:
                'labels.dataTable.resultsLimit.additional.linkText1',
              function1: () => {},
            },
            pictogramSrc:
              'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/66c25eb795cd471a8e7e1dc06c32408a?v=1ccadacd',
          },
          noSearchResults: {
            format:
              'labels.advancedSearch.tabs.patient.table.noSearchResults.format',
            content:
              'labels.advancedSearch.tabs.patient.table.noSearchResults.content',
            pictogramSrc:
              'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/66c25eb795cd471a8e7e1dc06c32408a?v=1ccadacd',
            additional: {
              textOrder:
                'labels.advancedSearch.tabs.patient.table.noSearchResults.additional.textOrder',
              text1:
                'labels.advancedSearch.tabs.patient.table.noSearchResults.additional.text1',
              // text2:
              //   'labels.dataTable.noSearchResults.additional.text2',
              linkText1:
                'labels.advancedSearch.tabs.patient.table.noSearchResults.additional.linkText1',
              // linkText2:
              //   'labels.dataTable.noSearchResults.additional.linkText2',
              function1: handleCreatePatient,
              function2: () => {},
            },
          },
          lazyLoadOptions: {
            pageSize: 25,
            loadMoreText:
              'labels.advancedSearch.lazyLoadOptions.loadMoreText',
          },
          handleSortDataChange: () => {}, // callback function
          columns: [
            {
              display: 'labels.patients.columnHeaders.name',
              field: 'name',
              format: {
                type: 'link',
                onClick: handleViewPatientRecord,
              },
              hiddenColumnView: 'mobile',
              sortable: false,
            },
            {
              display: 'labels.patients.columnHeaders.cochlearId',
              field: 'cochlearId',
              format: {
                type: 'string',
                onClick: null,
              },
              hiddenColumnView: 'mobile',
              sortable: false,
            },
            {
              display: 'labels.patients.columnHeaders.dob',
              field: 'dob',
              format: { onClick: null, type: 'string' },
              hiddenColumnView: 'mobile',
              sortable: false,
            },
          ],
        },

        agreementModalConfig: {
          agreementLabel:
            'labels.advancedSearch.tabs.patient.agreementModal.notification',
          title:
            'labels.advancedSearch.tabs.patient.agreementModal.title',
          cancel:
            'labels.advancedSearch.tabs.patient.agreementModal.cancel',
          submit:
            'labels.advancedSearch.tabs.patient.agreementModal.agree',
          content:
            'labels.advancedSearch.tabs.patient.agreementModal.content',
          onClose: handleAgreementModalClose,
          onSubmit: handleAgreementModalSubmit,
          visible: false,
        },

        submitButtonLabel:
          'labels.advancedSearch.tabs.patient.submitButton',
        submitButtonTooltip:
          'labels.advancedSearch.tabs.patient.submitButtonTooltip',
        clinicSelectorConfig,
      },
      {
        id: 'device',
        title: 'labels.advancedSearch.tabs.device.header',
        disabled: true,
      },
      // {
      //   id: 'service-request',
      //   title: 'labels.advancedSearch.tabs.serviceRequest.header',
      //   disabled: true,
      // },
    ],
  };
};
