export function getMergedAddress(provider) { 
    const street =
    provider.address?.street &&
    provider.address?.street?.[0] !== null
      ? `${provider.address.street},`
      : '';
  const city = 
    provider.address?.city &&
    provider.address?.city !== null 
    ? `${provider.address.city},`
    : '';
  const ctry = provider.address?.country &&
    provider.address?.country !== null 
    ? `${provider.address.country}`
    : '';
  return`${street} ${city} ${ctry}`;
}