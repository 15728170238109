/* eslint-disable no-param-reassign */
import React from 'react';
import {
  Container,
  Row,
  Col,
  SimpleCard,
  Title,
} from '@cochlear-design-system/foundation';

export const Landing = (landingPageDetails) => {
  const { config, labels } = landingPageDetails;

  const buildCardList = (cardList) => {
    cardList = cardList.map((cardItem, index) => {
      cardItem.title =
        labels[`labels.createPatientLanding.card${index + 1}.title`];
      cardItem.bodyText =
        labels[
          `labels.createPatientLanding.card${index + 1}.content`
        ];
      cardItem.button.text =
        labels[
          `labels.createPatientLanding.card${index + 1}.buttonText`
        ];
      return cardItem;
    });
    return cardList;
  };

  const cardList = buildCardList(config.cardList);

  const renderCards = (cardsList) => {
    return cardsList.map((cardArgs) => (
      <Col xs={4}>
        <SimpleCard className="simple-card-overides" {...cardArgs} />
      </Col>
    ));
  };

  return (
    <div className="initial-page-wrapper">
      <Container>
        <Row>
          <Col className="page-title">
            <Title
              content="Create patient"
              size="heading-2"
              tag="h5"
            />
          </Col>
        </Row>
        <Row>{renderCards(cardList)}</Row>
      </Container>
    </div>
  );
};
