import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userService from '../../services/userService';
import providerService from '../../services/providerService';
import { routes } from '../../config.js';
import { cochlearIdToUuid } from '../../utils/mcpcid';
import { replaceTokens } from '../../utils/stringFns';
import featureFlagService from '../../services/featureFlagService';
import headerCancelModal from '../../actions/headerCancelModalAction';
import { getHeaderConfig } from './headerNewConfig';

export const dataMapper = (
  fields,
  callback,
  lang,
  country,
  countryConfig,
  onClinicSelected,
  labels,
  providerCountryCode,
  providerRegion,
) => {
  const headerConfig = getHeaderConfig(
    providerCountryCode,
    providerRegion,
    labels,
  );

  const featureFlags = featureFlagService.getFeatureFlags();
  const pDetails = providerService.getProviderDetails();
  const uDetails = userService.getUserDetails();
  const currentPage = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [hideNavOnChange, setHideNavOnChange] = useState(false);
  const initials =
    uDetails.firstName && uDetails.lastName
      ? `${uDetails.firstName[0]}${uDetails.lastName[0]}`
      : '';

  const fullName =
    uDetails.firstName && uDetails.lastName
      ? `${uDetails.firstName} ${uDetails.lastName}`
      : '';

  let selected = '';
  const menuIDprefix = `menu-`;

  const hideHeaderMenus =
    currentPage.pathname.match(
      /(create-patient|device-registration|onboarding-qr-code|patients\/edit|add-carer|accessory-registration-review)/,
    ) !== null;
  const isCreatePatientRecipient =
    currentPage.pathname.match(/(create-patient-recipient)/) !== null;
  const isCreatePatientCarer =
    currentPage.pathname.match(/(create-patient-carer)/) !== null;
  const isOnboardingQrCode =
    currentPage.pathname.match(/(onboarding-qr-code)/) !== null;
  // TODO: make page specific
  const analytics = 'cancel_header';

  headerConfig.topNavMenus.targetItem.children.forEach((menu) => {
    menu.items.forEach((item) => {
      if (
        item.type.linkType !== 'external' &&
        item.url.value &&
        currentPage.pathname.endsWith(item.url.value)
      )
        selected = `${menuIDprefix}${menu.name.value.replace(
          /\s+/g,
          '',
        )}`;
    });
  });

  const getRemoteCheckUrl = () => {
    return `${routes.clinicianPortal.remoteCheck}${cochlearIdToUuid(
      pDetails.providerId,
    )}`;
  };

  const arrTokens = [
    {
      key: '{{##___REMOTE_CHECK_URL___##}}',
      value: getRemoteCheckUrl(),
    },
  ];

  const dataFeatureToMenuMapper = [
    {
      menuitem: 'Dashboard',
      enabled: featureFlags.dashboardMenu,
    },
    {
      menuitem: 'Patients',
      enabled: featureFlags.patientsMenu,
    },
    {
      menuitem: 'Create patient',
      enabled: featureFlags.createPatient,
    },
    {
      menuitem: 'Devices',
      enabled: featureFlags.devicesMenu,
    },
    {
      menuitem: 'Device Registration - DEMO page',
      enabled: featureFlags.deviceRegistration,
    },
    {
      menuitem: 'Unassigned devices',
      enabled: featureFlags.unassignedDevicesSubMenu,
    },
    {
      menuitem: 'Clinic loan devices',
      enabled: featureFlags.clinicLoanDevicesSubMenu,
    },
    {
      menuitem: 'Service requests',
      enabled: featureFlags.serviceRequestsSubMenu,
    },
    {
      menuitem: 'Program file requests',
      enabled: featureFlags.programFileRequests,
    },
    {
      menuitem: 'Find a device',
      enabled: featureFlags.findASeviceSubMenu,
    },

    { menuitem: 'Clinic admin', enabled: featureFlags.clinicAdmin },
    {
      menuitem: 'Clinician management',
      enabled: featureFlags.cliniciansSubMenu,
    },
    {
      menuitem: 'Clinic equipment',
      enabled: true,
    },
    {
      menuitem: 'Clinic contacts',
      enabled: featureFlags.clinicContactsSubMenu,
    },

    { menuitem: 'Remote Check', enabled: featureFlags.remoteCheck },

    {
      menuitem: 'Custom Sound Pro',
      enabled: true,
    },
    {
      menuitem: 'Launch simulation software',
      enabled: true,
    },
    {
      menuitem: 'Online training course',
      enabled: true,
    },

    {
      menuitem: 'Resources',
      enabled: providerRegion !== 'EMEA' && providerRegion !== 'APAC',
    },
    // { menuitem: 'Overview', enabled: featureFlags.overviewSubMenu },
    // {
    //   menuitem: 'Product Information',
    //   enabled: featureFlags.fittingInstructionsAndDocsSubMenu,
    // },
    // {
    //   menuitem: 'Resources-subMenu',
    //   enabled: featureFlags.candidacyToolsSubMenu,
    // },
    // {
    //   menuitem: 'For your patients',
    //   enabled: featureFlags.forYourPatientsSubMenu,
    // },
    // {
    //   menuitem: 'Clinic solutions',
    //   enabled: featureFlags.clinicSolutionsSubMenu,
    // },
    {
      menuitem: 'CPN Resources',
      enabled: providerRegion === 'CAM',
    },
    { menuitem: 'Help centre', enabled: false },
    {
      menuitem: 'Contact us',
      enabled: true,
    },
    {
      menuitem: 'Return to Legacy Portal',
      enabled: true,
    },
  ];

  const isMenuFeatureEnabled = (menu) => {
    const menuInList = dataFeatureToMenuMapper.filter(
      (item) => menu === item.menuitem && item.enabled,
    )[0];
    return menuInList; // Return null (false) if menu is not in the mapping list or feature is disabled
  };

  const getCloseLinkLabel = () => {
    if (currentPage.pathname.match(/(patients\/edit)/) !== null) {
      return labels.closeLink;
    }
    return labels.cancelLink;
  };

  const handleCloseLinkClick = () => {
    if (
      isCreatePatientRecipient ||
      isCreatePatientCarer ||
      isOnboardingQrCode
    ) {
      history.push(
        `/${country}/${lang}/${routes.dpx.createPatientLanding}`,
      );
    } else {
      dispatch(headerCancelModal(true));
    }
  };

  return {
    data: {
      button: {
        initials,
      },
      menuHeader: {
        content1: fullName,
      },
      clinicSelector: {
        items: pDetails.providerList.providers.map((provider) => ({
          id: provider.id,
          content: provider.name,
          analytics: 'select_provider_header',
        })),
        selected:
          pDetails.providerList.providers.filter(
            (providerItem) => providerItem.id === pDetails.providerId,
          )[0]?.id || pDetails.providerList.providers?.[0]?.id,
      },
    },
    config: {
      showLogo: true,
      logoSrc:
        'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/d7d76035265f4eb6b4817ca927285c5d?v=3ef6f186',
      logoAlt: labels.logoAlt,
      showSeparator: true,
      showAppName: true,
      showClinicSelector:
        !hideHeaderMenus &&
        pDetails.providerList.providers.length > 0,
      AppName: 'appName',
      showMyProfile: !hideHeaderMenus,
      closeLink: getCloseLinkLabel(),
      cancelLink: labels.cancelLink,
      closeLinkClick: handleCloseLinkClick,
      showCloseLink: hideHeaderMenus,
      showCancelLink: hideHeaderMenus,
      hideTopNav: hideHeaderMenus || hideNavOnChange,
      analytics,
      clinicSelector: {
        onSelectItem: async function onSelectItem(item) {
          setHideNavOnChange(true);
          onClinicSelected(item, uDetails?.professionalId);
        },
      },
      topNavMenus: {
        selected,
        menus: headerConfig.topNavMenus.targetItem.children
          .filter((topNavMenu) =>
            isMenuFeatureEnabled(topNavMenu.name.value),
          )
          .map((topNavSubMenu) => {
            const menuId = `${menuIDprefix}${topNavSubMenu.name.value.replace(
              /\s+/g,
              '',
            )}`;
            return {
              id: menuId,
              onChange: (selItem) => callback(selItem),
              items: topNavSubMenu.items
                .filter((subMenu) =>
                  isMenuFeatureEnabled(subMenu.name),
                )
                .map((item, subindex) => ({
                  type:
                    item.type.linkType === 'external'
                      ? 'External'
                      : 'SimpleText',
                  text: labels[item.displayName.value],
                  id: `${menuId}-${subindex}`,
                  url: replaceTokens(
                    !item.url.value ? item.type.url : item.url.value,
                    arrTokens,
                  ),
                })),
            };
          }),
      },
      profileMenu: {
        showHeader: true,
        header: {
          showPictogram: false,
          pictogramSrc:
            'https://sit01-cochlear.cs102.force.com/Professional/resource/1641854542000/DPX_Portal_Static_Resources/src/assets/images/EmailSent.svg?orgId=00D1j0000008arI',
          pictogramAlt: labels.pictogramAlt,
        },
        links: headerConfig.links.targetItem.children.map((link) => ({
          path:
            link.type.linkType === 'external'
              ? link.type.url
              : `/${country}/${lang}/${link.url.value}`,
          text: labels[link.name],
          icon: link.icon.value,
        })),
        showWhatsNew: false,
        whatsNew: {
          text: labels.whatsNew,
          onClick: function onClick() {},
        },
      },
    },
    caseTypes: headerConfig.caseTypes,
    channel: headerConfig.channel,
  };
};
