import React from 'react';
import { Modal, Button } from '@cochlear-design-system/foundation';

export const ModalFooter = ({ hideModal }) => {
  return (
    <Modal.Footer>
      <Button
        text="Close"
        variant="secondary"
        onClick={hideModal}
        data-analytics="close_find_clinic"
      />
    </Modal.Footer>
  );
};
