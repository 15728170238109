import axios from 'axios';
import { providerListEndpoint } from '../../config';
import getHeaders from '../../utils/getHeaders';
import { logger } from '../../logger';

const log = logger();

export const fetchAccessoryItems = async (countryCode) => {
  try {
    const response = await axios.get(
      `${providerListEndpoint}/products/${countryCode}`,
      getHeaders(),
    );
    if (response.status === 200 && response.data) {
      return {
        apiResponse: `${response.status}`,
        deviceList: response.data,
      };
    }
    return {
      apiResponse: `${response.status}`,
      deviceList: [],
    };
  } catch (err) {
    log.error('Error fetching accessory items', err);
    return {
      apiResponse: `400`,
      deviceList: [],
    };
  }
};

export const fetchMatchingAccessories = async (
  countryCode,
  requestBody,
) => {
  try {
    const response = await axios.post(
      `${providerListEndpoint}/products/accessory/${countryCode}?mapping=raw`,
      requestBody,
      getHeaders(),
    );
    if (response.status === 200 && response.data) {
      return {
        apiResponse: `${response.status}`,
        accessoryList: response.data,
      };
    }
    return {
      apiResponse: `${response.status}`,
      accessoryList: [],
    };
  } catch (err) {
    log.error('Error fetching matching accessories', err);
    return {
      apiResponse: `400`,
      accessoryList: [],
    };
  }
};
