import { getFindClinicModalConfig } from '../../cdsConfigs/findClinicModalConfig';

export const getConfig = (
  handleSearchClinic,
  handleSelectClinic,
  handleRemoveClinic,
  onMatchingRecordRowClick,
  onMatchingRecordRowClickCarer,
  userCountry,
  dateInputFormat,
  labels,
) => {
  const clinicSelectorConfig = getFindClinicModalConfig(
    userCountry,
    labels,
  );
  clinicSelectorConfig.searchButton.onClick = handleSearchClinic;
  clinicSelectorConfig.countryField.disabled = false;
  clinicSelectorConfig.tableConfig.events.onRowClick =
    handleSelectClinic;

  return {
    formats: {
      date: dateInputFormat,
    },
    fields: [
      {
        id: 'firstName',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.advancedSearch.tabs.patient.errors.firstName.required',
          },
          {
            type: 'max',
            value: 40,
            message:
              'labels.createPatient.validation.firstName.invalidMaxLength',
          },
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.errors.firstName.required',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.createPatient.edit.firstname.validation.specialChars',
          },
        ],
      },
      {
        id: 'middleName',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.advancedSearch.tabs.patient.errors.firstName.required',
          },
          {
            type: 'max',
            value: 40,
            message:
              'labels.createPatient.validation.middleName.invalidMaxLength',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.createPatient.validation.middleName.noSpecialCharacters',
          },
        ],
      },
      {
        id: 'lastName',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.advancedSearch.tabs.patient.errors.lastName.required',
          },
          {
            type: 'max',
            value: 80,
            message:
              'labels.createPatient.validation.lastName.invalidMaxLength',
          },
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.errors.lastName.required',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.createPatient.edit.lastname.validation.specialChars',
          },
          {
            type: 'matches',
            value: /^[^\d]+$/,
            message:
              'labels.createPatient.edit.lastname.validation.noNumbers',
          },
        ],
      },
      {
        id: 'dateOfBirth',
        label:
          'labels.advancedSearch.tabs.patient.fields.dateOfBirth.label',
        fieldLabels: {
          d: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.dayLabel',
          m: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.monthLabel',
          y: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.yearLabel',
        },
        validators: [
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.fields.dateOfBirth.validators.required',
          },
        ],
      },

      {
        id: 'email',
        label:
          'labels.advancedSearch.tabs.patient.fields.accountUsername.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.accountUsername.hint',
        validators: [
          {
            type: 'matches',
            value:
              /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
            message:
              'labels.advancedSearch.tabs.patient.errors.accountUsername.validEmail',
          },
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.errors.accountUsername.required',
          },
          {
            type: 'max',
            value: 55,
            message:
              'labels.advancedSearch.tabs.patient.errors.accountUsername.validEmail',
          },
        ],
      },
      {
        id: 'firstNameCarer',
        label:
          'labels.advancedSearch.tabs.patient.fields.firstName.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.firstName.hint',
        placeholder:
          'labels.advancedSearch.tabs.patient.fields.firstName.placeholder',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.advancedSearch.tabs.patient.errors.firstName.required',
          },
          {
            type: 'max',
            value: 40,
            message:
              'labels.createPatient.validation.firstName.invalidMaxLength',
          },
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.errors.firstName.required',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.createPatient.edit.firstname.validation.specialChars',
          },
        ],
      },
      {
        id: 'middleNameCarer',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.advancedSearch.tabs.patient.errors.firstName.required',
          },
          {
            type: 'max',
            value: 40,
            message:
              'labels.createPatient.validation.middleName.invalidMaxLength',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.createPatient.validation.middleName.noSpecialCharacters',
          },
        ],
      },
      {
        id: 'lastNameCarer',
        label:
          'labels.advancedSearch.tabs.patient.fields.lastName.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.lastName.hint',
        placeholder:
          'labels.advancedSearch.tabs.patient.fields.lastName.placeholder',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.advancedSearch.tabs.patient.errors.lastName.required',
          },
          {
            type: 'max',
            value: 80,
            message:
              'labels.createPatient.validation.lastName.invalidMaxLength',
          },
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.errors.lastName.required',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.createPatient.edit.lastname.validation.specialChars',
          },
          {
            type: 'matches',
            value: /^[^\d]+$/,
            message:
              'labels.createPatient.edit.lastname.validation.noNumbers',
          },
        ],
      },
      {
        id: 'dateOfBirthCarer',
        label:
          'labels.advancedSearch.tabs.patient.fields.dateOfBirth.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.hint',
        fieldLabels: {
          d: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.dayLabel',
          m: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.monthLabel',
          y: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.yearLabel',
        },
        validators: [
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.fields.dateOfBirthCarer.validators.required',
          },
        ],
      },
    ],
    clinicSelectorConfig,
    selectedClinicTable: {
      highlight: false,
      columns: [
        {
          display:
            'labels.findClinicModal.clinicsTable.columnHeaders.name',
          field: 'name',
          format: {
            type: 'string',
          },
          sortable: false,
        },
        {
          default: 'asc',
          display:
            'labels.findClinicModal.clinicsTable.columnHeaders.address',
          field: 'address',
          format: {
            type: 'string',
          },
          sortable: false,
          syncSort: 'orderNumberOrderDate',
        },
        {
          display: '',
          field: 'id',
          format: {
            additionalConfig: {
              className: 'remove',
              conditional: 'isRemovable',
              onClick: handleRemoveClinic,
              text: 'labels.findClinicModal.remove',
              visible: undefined,
            },
            type: 'button',
          },
          sortable: false,
        },
      ],
      formats: {
        currency: 'USD',
        date: 'mmm dd yyyy',
      },
      handleSortDataChange: function noRefCheck() {},
      loading: false,
      setLoading: function noRefCheck() {},
      setShow: function noRefCheck() {},
      show: false,
    },
    matchingPatientsTable: {
      highlight: false,
      columns: [
        {
          display: 'labels.common.name',
          field: 'name',
          format: {
            collapsed: {
              type: 'composite',
              template:
                '<div><span class="ccl__text ccl__text--interactive-text">{{name}}</span></div><em></em></div>',
            },
            expanded: {
              type: 'emptycell',
            },
            onClick: () => {},
          },
          sortable: false,
        },
        {
          default: 'asc',
          display: 'labels.common.dateOfBirth',
          field: 'dob',
          format: {
            type: 'string',
          },
          sortable: false,
          syncSort: 'orderNumberOrderDate',
        },
        {
          display: 'labels.common.contactEmail',
          field: 'email',
          format: {
            type: 'string',
          },
          sortable: false,
        },
      ],
      formats: {
        currency: 'USD',
        date: 'mmm dd yyyy',
      },
      handleSortDataChange: function noRefCheck() {},
      loading: false,
      setLoading: function noRefCheck() {},
      setShow: function noRefCheck() {},
      show: false,
      clickableRow: true,
      events: {
        onLoadMore: () => {},
        onRowClick: (actions, row) => {
          onMatchingRecordRowClick(row.data);
        },
        onSort: () => {},
      },
    },
    matchingPatientsTableCarer: {
      highlight: false,
      columns: [
        {
          display: 'labels.common.name',
          field: 'name',
          format: {
            collapsed: {
              type: 'composite',
              template:
                '<div><span class="ccl__text ccl__text--interactive-text">{{name}}</span></div><em></em></div>',
            },
            expanded: {
              type: 'emptycell',
            },
            onClick: () => {},
          },
          sortable: false,
        },
        {
          default: 'asc',
          display: 'labels.common.dateOfBirth',
          field: 'dobFormatted',
          format: {
            type: 'string',
          },
          sortable: false,
          syncSort: 'orderNumberOrderDate',
        },
        {
          display: 'labels.common.contactEmail',
          field: 'email',
          format: {
            type: 'string',
          },
          sortable: false,
        },
      ],
      formats: {
        currency: 'USD',
        date: 'mmm dd yyyy',
      },
      handleSortDataChange: function noRefCheck() {},
      loading: false,
      setLoading: function noRefCheck() {},
      setShow: function noRefCheck() {},
      show: false,
      clickableRow: true,
      events: {
        onLoadMore: () => {},
        onRowClick: (actions, row) => {
          onMatchingRecordRowClickCarer(row.data);
        },
        onSort: () => {},
      },
    },
    patientAgreementmodelConfig: {
      alertTitle: 'A patient with these details already exist',
      alertText:
        labels[
          'labels.advancedSearch.tabs.patient.agreementModal.notification'
        ],
      agreementBodyText:
        labels[
          'labels.advancedSearch.tabs.patient.agreementModal.content'
        ],
      agreementAccceptText:
        labels[
          'labels.advancedSearch.tabs.patient.agreementModal.agree'
        ],
      agreementCloseText:
        labels[
          'labels.advancedSearch.tabs.patient.agreementModal.cancel'
        ],
      agreementTitle:
        labels['labels.createPatient.agreementModal.title'],
      userType: 'recipient',
    },
    carerAgreementmodelConfig: {
      alertTitle:
        labels[
          'labels.createPatient.agreementModal.confirmCarer.title'
        ],
      alertText:
        labels[
          'labels.createPatient.agreementModal.confirmCarer.message'
        ],
      agreementBodyText: '',
      agreementAccceptText: labels['labels.common.confirm'],
      agreementCloseText:
        labels[
          'labels.advancedSearch.tabs.patient.agreementModal.cancel'
        ],
      agreementTitle: '',
      userType: 'carer',
    },
  };
};
