/* eslint-disable  import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFeature } from 'flagged';
import {
  Container,
  Row,
  Col,
  Button,
  Title,
  Text,
  Alert,
} from '@cochlear-design-system/foundation';
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import providerService from '../../services/providerService';
import { routes, onboardingURL } from '../../config';
import headerCancelModal from '../../actions/headerCancelModalAction';
import { getFindClinicModalConfig } from '../../cdsConfigs/findClinicModalConfig';
import { getLabelsForFields } from '../../utils/labelHelper';
import { FindClinicModal } from '../../ui/FindClinicModal/FindClinicModal';
import { getMergedAddress } from '../../utils/addressHelper';

const OnboardingQRCode = ({ rendering, country, lang }) => {
  const dispatch = useDispatch();
  const userCountry = country.toUpperCase();
  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );
  const { name: clinicName, address } =
    providerService.getCurrentProvider();
  const { hasClinic, providerId } =
    providerService.getProviderDetails();
  const openPathPrefix = `${onboardingURL}lang=${lang}-${
    address.countryCode ?? userCountry
  }&clinicid=${providerId}`;
  const [openPath, setOpenPath] = useState(openPathPrefix);
  const [
    showSelectSurgeryCentreModal,
    setShowSelectSurgeryCentreModal,
  ] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [clinicData, setClinicData] = useState([]);
  const labels = getLabelsForFields(rendering.fields);

  const handleSearchClinic = async (searchParam) => {
    const countryCode = searchParam.country || userCountry;
    const clinics = await providerService.searchClinics(
      countryCode,
      searchParam.clinic,
    );
    /* eslint no-param-reassign: ["error", { "props": false }] */
    if (clinics.providers && clinics.providers.length > 0) {
      clinics.providers.forEach((provider) => {
        provider.address = getMergedAddress(provider);
      });
    }
    return clinics.providers;
  };

  const handleSelectClinic = (action, params) => {
    const selectedClinicData = [];
    selectedClinicData.push({
      clinicId: params.data.id,
      name: params.data.name,
      address: params.data.address,
      isRemovable: true,
    });
    setClinicData(selectedClinicData);
  };

  const clinicSelectorConfig = getFindClinicModalConfig(
    userCountry,
    labels,
  );
  clinicSelectorConfig.searchButton.onClick = handleSearchClinic;
  clinicSelectorConfig.tableConfig.events.onRowClick =
    handleSelectClinic;

  const getQRCode = () => {
    return (
      <QRCodeCanvas
        id="qrCode"
        value={openPath}
        size={250}
        bgColor="#ffffff"
        level="H"
      />
    );
  };

  const redirectToHome = () => {
    window.location.href = `${routes.dpx.home}`;
  };

  const getSurgeryCentreName = () => {
    if (clinicData.length) {
      const [first] = clinicData;
      return first.name;
    }
    return 'N/A';
  };

  const getSurgeryCentreActionType = () => {
    if (clinicData.length) {
      return 'Update';
    }
    return 'Add';
  };

  useEffect(() => {
    if (modalTrigger) {
      dispatch(headerCancelModal(false));
      redirectToHome();
    }
  }, [modalTrigger]);

  useEffect(() => {
    if (clinicData.length > 0) {
      const [first] = clinicData;
      const path = `${openPathPrefix}&surgeryid=${first.clinicId}`;
      setOpenPath(path);
    }
  }, [clinicData]);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsCopied(false);
    }, 55000);
    return () => {
      clearTimeout(timerId);
    };
  }, [isCopied]);

  const openInNewTab = () => {
    window.open(openPath, '_blank', 'noreferrer');
  };

  return (
    <>
      <Container className="ccl__qr-code-container">
        <Row>
          <Col>
            <Title
              content="Onboarding QR code/link"
              size="heading-1"
              tag="h1"
              className="qr-code-page-title"
            />
            <Title
              content="Scan QR Code"
              size="heading-1"
              tag="h1"
              className="qr-code-page-title-print"
            />
          </Col>
        </Row>
        <div className="qr-code-wrapper">
          <Row>
            <Col>
              <Title
                content="Create a Cochlear account"
                size="heading-2"
                tag="h2"
                className="qr-code-title"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text
                content="Please scan this QR code to start your Cochlear account creation."
                type="body-text-bold"
                className="qr-code-body-print"
              />
              <Text
                content={`Clinic: ${clinicName}`}
                type="body-text"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text
                content={`Surgery Centre: ${getSurgeryCentreName()}`}
                type="body-text"
                className={`${
                  getSurgeryCentreName() === 'N/A'
                    ? 'qr-code-surgery-centre-na'
                    : ''
                } `}
              />
            </Col>
          </Row>
          {hasClinic && (
            <Row>
              <Col>
                <Text
                  content={`${getSurgeryCentreActionType()} Surgery Centre`}
                  type="link-text"
                  onClick={() => {
                    setShowSelectSurgeryCentreModal(true);
                  }}
                  className="qr-code-select-surgery-centre"
                  data-analytics="add_clinic_send_qrcode"
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="qr-code">{getQRCode()}</div>
            </Col>
          </Row>
          <Row>
            <Col className="qr-code-print-btn">
              <Button
                text="Print QR Code"
                onClick={() => window.print()}
                data-analytics="print_send_qrcode"
              />
            </Col>
            <Col className="qr-code-copy-link-btn">
              <CopyToClipboard text={openPath}>
                <Button
                  text="Copy onboarding link"
                  variant="secondary"
                  onClick={() => setIsCopied(true)}
                  data-analytics="copy_send_qrcode"
                />
              </CopyToClipboard>
            </Col>
          </Row>
          <Row>
            <Col className="qr-code-open-link-btn">
              <Button
                text="Open link in new tab"
                variant="tertiary"
                onClick={() => openInNewTab()}
                data-analytics="open_tab_send_qrcode"
              />
            </Col>
          </Row>
        </div>
        {isCopied && (
          <div className="qr-code-alert-wrapper">
            <Alert
              dismissible
              heading="Link copied!"
              variant="brand"
              onClose={() => setIsCopied(false)}
              data-analytics="close_copy_send_qrcode"
            />
          </div>
        )}
      </Container>
      <FindClinicModal
        {...clinicSelectorConfig}
        show={showSelectSurgeryCentreModal}
        onHide={() => setShowSelectSurgeryCentreModal(false)}
        labels={labels}
      />
    </>
  );
};

export default withFeature('featureEnabler/createPatient')(
  OnboardingQRCode,
);
