/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import {
  Text,
  Spinner,
  Button,
  IconButton,
} from '@cochlear-design-system/foundation';
import { DataTable } from '@cochlear-design-system/features.dataTable';
import { Error } from '../Error/Error';
import { TaskBar } from '../TaskBar/TaskBar';
// eslint-disable-next-line import/no-cycle
import { EquipmentWrapper } from './EquipmentWrapper';

export const EquipmentSummary = ({
  config,
  data,
  labels,
  leftData,
  rightData,
  unknownData,
}) => {
  const [loading, setLoading] = useState(true);
  const [switchView, setSwitchView] = useState(false);
  if (config.taskBarConfig) {
    config.taskBarConfig.onSwitchView = () => {
      setSwitchView(true);
      config.toolbarConfig.resetDeviceFilters();
    };
  }

  useEffect(() => {
    if (config.isLoading === false) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [config.isLoading]);

  if (loading) {
    return (
      <div className="ccl__spinner">
        <Spinner animation="border" />
        <Text content={labels[config.labels.loadingEquipment]} />
      </div>
    );
  }

  if (config.hasError) {
    return (
      <Error config={config.errorConfig} labels={labels} data={{}} />
    );
  }

  if (config.isEmpty) {
    return (
      <>
        {config.refreshButton && (
          <div
            style={{
              float: 'right',
              marginRight: '32px',
              marginTop: '32px',
            }}
          >
            <IconButton
              variant={config.refreshButton.variant}
              icon={config.refreshButton.icon}
              handleClick={config.refreshButton.onClick}
              analytics={config.refreshButton.analytics}
            />
          </div>
        )}
        <div className="ccl__align-cm5">
          <Text content={labels[config.labels.emptyEquipment]} />
          <br />
          <Button
            text={labels[config.labels.addDevice]}
            variant="brand-primary"
            icon="add"
            iconPosition="leading"
            size="medium"
            onClick={config.emptyEquipment.button.onClick}
            data-analytics={config.emptyEquipment.button.analytics}
          />
        </div>
      </>
    );
  }

  if (
    config.taskBarConfig.labels &&
    config.taskBarConfig.labels.summary
  ) {
    config.taskBarConfig.labels.switchText =
      config.taskBarConfig.labels.summary.switchText;
    config.taskBarConfig.labels.taskBarTitle =
      config.taskBarConfig.labels.summary.taskBarTitle;
  }
  config.taskBarConfig.analyticsTag = switchView
    ? config.taskBarConfig.analytics.allDevices
    : config.taskBarConfig.analytics.summary;

  return (
    (switchView && (
      <EquipmentWrapper
        config={config}
        data={data}
        labels={labels}
        leftData={leftData}
        rightData={rightData}
        unknownData={unknownData}
      />
    )) || (
      <div className="ccl__align-cm2">
        <TaskBar config={config.taskBarConfig} labels={labels} />
        <br />
        {rightData?.length > 0 && (
          <div className="ccl__data-table--dpx ccl__data-table--dpx--equipment">
            <DataTable
              config={config.tableConfig.tableConfigRight}
              data={rightData}
              labels={labels}
            />
            <br />
          </div>
        )}
        {leftData?.length > 0 && (
          <div className="ccl__data-table--dpx ccl__data-table--dpx--equipment">
            <DataTable
              config={config.tableConfig.tableConfigLeft}
              data={leftData}
              labels={labels}
            />
            <br />
          </div>
        )}
        {unknownData?.length > 0 && (
          <div className="ccl__data-table--dpx ccl__data-table--dpx--equipment">
            <DataTable
              config={config.tableConfig.tableConfigUnknown}
              data={unknownData}
              labels={labels}
            />
          </div>
        )}
      </div>
    )
  );
};
