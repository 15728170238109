import { routes } from '../../config.js';

/* eslint-disable dot-notation */
export const getHeaderConfig = (
  providerCountryCode,
  providerRegion,
  labels,
) => {
  let cspCamTrainingUrl = routes.mcp.cspCamTraining;

  if (providerRegion === 'CAM') {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-cam',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'en');
  } else if (providerCountryCode === 'IT') {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-emea',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'it');
  } else if (providerCountryCode === 'DE') {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-emea',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'de');
  } else if (providerCountryCode === 'FR') {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-emea',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'fr');
  } else {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-cam',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'en');
  }

  return {
    topNavMenus: {
      targetItem: {
        children: [
          {
            name: {
              value: 'Dashboard',
            },
            items: [
              {
                name: 'Dashboard',
                displayName: {
                  value: 'dashboard',
                },
                url: {
                  value: 'home',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/home',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
            ],
          },
          {
            name: {
              value: 'Create patient',
            },
            items: [
              {
                name: 'Create patient',
                displayName: {
                  value: 'createPatient',
                },
                url: {
                  value: 'create-patient-landing',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/create-patient-landing',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
            ],
          },
          {
            name: {
              value: 'Devices',
            },
            items: [
              {
                name: 'Devices',
                displayName: {
                  value: 'devices',
                },
                url: {
                  value: 'devices',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/device-registration',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Device Registration - DEMO page',
                displayName: {
                  value: 'devicesRegistrationDemo',
                },
                url: {
                  value: 'device-registration',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/device-registration',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Unassigned devices',
                displayName: {
                  value: 'unassignedDevices',
                },
                url: {
                  value: 'dummypages/devices/unassigned-devices',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/dummypages/devices/unassigned-devices',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Clinic loan devices',
                displayName: {
                  value: 'clinicLoanDevices',
                },
                url: {
                  value: 'dummypages/devices/clinic-loan-devices',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/dummypages/devices/clinic-loan-devices',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Service requests',
                displayName: {
                  value: 'serviceRequests',
                },
                url: {
                  value: 'dummypages/devices/service-requests',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/dummypages/devices/service-requests',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Program file requests',
                displayName: {
                  value: 'programFileRequests',
                },
                url: {
                  value: 'dummypages/devices/program-file-requests',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/dummypages/devices/program-file-requests',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Find a device',
                displayName: {
                  value: 'findADevice',
                },
                url: {
                  value: 'dummypages/devices/find-a-device',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/dummypages/devices/find-a-device',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
            ],
          },
          {
            name: {
              value: 'Clinic admin',
            },
            items: [
              {
                name: 'Clinic admin',
                displayName: {
                  value: 'clinicAdmin',
                },
                url: {
                  value: '',
                },
                type: {
                  linkType: 'internal',
                  url: '',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Clinician management',
                displayName: {
                  value: 'clinicianManagement',
                },
                url: {
                  value: 'clinician-management',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/clinician-management',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Clinic contacts',
                displayName: {
                  value: 'clinicContacts',
                },
                url: {
                  value: 'dummypages/clinic-admin/clinic-contacts',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/dummypages/clinic-admin/clinic-contacts',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Clinic equipment',
                displayName: {
                  value: 'clinicEquipment',
                },
                url: {
                  value: 'clinic-equipment',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/clinic-equipment',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
            ],
          },
          {
            name: {
              value: 'Remote Check',
            },
            items: [
              {
                name: 'Remote Check',
                displayName: {
                  value: 'remoteCheck',
                },
                url: {
                  value: '{{##___REMOTE_CHECK_URL___##}}',
                },
                type: {
                  linkType: 'internal',
                  url: null,
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
            ],
          },
          {
            name: {
              value: 'Custom Sound Pro',
            },
            items: [
              {
                name: 'Custom Sound Pro',
                displayName: {
                  value: 'customSoundPro',
                },
                url: {
                  value: '',
                },
                type: {
                  linkType: 'internal',
                  url: '',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Launch simulation software',
                displayName: {
                  value: 'launchSimulationSoftware',
                },
                url: {
                  value: routes.mcp.cspVirtualSoftware,
                },
                type: {
                  linkType: 'external',
                  url: null,
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Online training course',
                displayName: {
                  value: 'onlineTrainingCourse',
                },
                url: {
                  value: cspCamTrainingUrl,
                },
                type: {
                  linkType: 'external',
                  url: null,
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
            ],
          },
          {
            name: {
              value: 'Resources',
            },
            items: [
              {
                name: 'For your patients',
                displayName: {
                  value: 'forYourPatients',
                },
                url: {
                  value: 'resources/for-your-patients',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/resources/for-your-patients',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Resources',
                displayName: {
                  value: 'resources',
                },
                url: {
                  value: 'resources',
                },
                type: {
                  linkType: 'internal',
                  url: '',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Overview',
                displayName: {
                  value: 'overview',
                },
                url: {
                  value: 'resources',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/resources',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Product Information',
                displayName: {
                  value: 'productInformation',
                },
                url: {
                  value: 'product-information',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/resources/product-information',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Resources-subMenu',
                displayName: {
                  value: 'resources',
                },
                url: {
                  value: 'resources/resources',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/resources/resources',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Clinic solutions',
                displayName: {
                  value: 'clinicSolutions',
                },
                url: {
                  value: 'resources/clinic-solutions',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/resources/clinic-solutions',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
            ],
          },
          {
            name: {
              value: 'CPN Resources',
            },
            items: [
              {
                name: 'CPN Resources',
                displayName: {
                  value: 'cpnResources',
                },
                url: {
                  value: 'cpn-resource',
                },
                type: {
                  linkType: 'internal',
                  url: null,
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
            ],
          },
          {
            name: {
              value: 'Help centre',
            },
            items: [
              {
                name: 'Help centre',
                displayName: {
                  value: 'helpCentre',
                },
                url: {
                  value: '',
                },
                type: {
                  linkType: 'internal',
                  url: '/us/en/dpx/dummypages/help-centre',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Contact us',
                displayName: {
                  value: 'contactUs',
                },
                url: {
                  value: '',
                },
                type: {
                  linkType: 'internal',
                  url: '#showContactUsModal',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
              {
                name: 'Return to Legacy Portal',
                displayName: {
                  value: 'returnOldPortal',
                },
                url: {
                  value: '',
                },
                type: {
                  linkType: 'internal',
                  url: '#showReturnOldPortalModal',
                  id: 'BE9E7836589340B189596887BCCFF55F',
                },
              },
            ],
          },
        ],
      },
    },
    links: {
      targetItem: {
        children: [
          {
            name: 'myAccount',
            url: {
              value: 'my-account',
            },
            type: {
              linkType: 'internal',
              url: '/us/en/dpx/my-account',
            },
            icon: {
              value: '',
            },
          },
          {
            name: 'logout',
            url: {
              value: '/authorize?action=logout',
            },
            type: {
              linkType: 'external',
              url: '/authorize?action=logout',
            },
            icon: {
              value: '',
            },
          },
        ],
      },
    },
    caseTypes: [
      {
        label: labels['portalIssue'],
        value: labels['portalIssue'],
      },
      {
        label: labels['deviceAppSupport'],
        value: labels['deviceAppSupport'],
      },
      {
        label: labels['orderStatus'],
        value: labels['orderStatus'],
      },
      {
        label: labels['accountLoginSupport'],
        value: labels['accountLoginSupport'],
      },
      {
        label: labels['surgicalSupport'],
        value: labels['surgicalSupport'],
      },
      {
        label: labels['researchSupport'],
        value: labels['researchSupport'],
      },
      {
        label: labels['remoteCheck'],
        value: labels['remoteCheck'],
      },
      {
        label: labels['somethingElse'],
        value: labels['somethingElse'],
      },
      {
        label: labels['returnOldPortal'],
        value: labels['returnOldPortal'],
      },
    ],
    channel: 'DPX',
  };
};
