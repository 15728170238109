import React, {
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  PageHeader,
  Tabs,
  Alert,
  TextInput,
  Text,
  Button,
  RadioGroup,
  Modal,
} from '@cochlear-design-system/foundation';
import { DataTable } from '@cochlear-design-system/features.dataTable';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Error } from '../Error/Error';
// eslint-disable-next-line no-unused-vars
import AccessoryRegistration from '../../components/AccessoryRegistration';
import patientService from '../../services/patientService';
import { tabIndex } from './getTabIndex';
import { logger } from '../../logger';

const log = logger();

export const DeviceRegistrationWrapper = forwardRef(
  (deviceRegistrationDetails, ref) => {
    const [showModal, setShowModal] = useState(false);
    useImperativeHandle(ref, () => ({
      setModalState(state) {
        setShowModal(state);
      },
    }));

    const {
      config,
      labels,
      data,
      dataTableDetails,
      patientName,
      patientId,
      providerId,
      professionalId,
      patientLink,
      error,
      errorApi,
      noExistingDevices,
      deviceAlreadyRegistered,
      modalConfig,
    } = deviceRegistrationDetails;

    const {
      pageTitle,
      infoMessageTab1,
      noExistingDevicesAlert,
      searchText,
      searchingText,
      onChangeTab,
      tabs,
    } = config;
    const { findDevice } = tabs[0];
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const { hasExistingDevice } = location.state;
    const cacheControlHeader = {
      'cache-control': 'no-cache',
    };

    const [deviceSearchType, setDeviceSearchType] = useState(
      findDevice.searchType,
    );
    const [searchLabel, setSearchLabel] = useState(
      labels[tabs[0].fields[0].label[deviceSearchType]],
    );
    const [searchHint, setSearchHint] = useState(
      labels[tabs[0].fields[0].hint[deviceSearchType]],
    );
    const [dataTableConfig, setDataTableConfig] = useState(
      dataTableDetails.config,
    );
    const [isLotControlled, setIsLotControlled] = useState(false);
    const [canRegister, setCanRegister] = useState(true);

    const { parameters } = data;
    const patientNameFormatted = patientName
      ? patientName.toUpperCase()
      : '';
    const onChange = () => {};
    const [apiError, setApiError] = useState(false);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [refreshedCache, setRefreshedCache] = useState(false);
    const handleClose = () => setShowModal(false);

    const getInputValidations = (inputNames, fieldsConfig) =>
      Yup.object(
        inputNames.reduce((acc, inputName) => {
          return {
            ...acc,
            [inputName]: fieldsConfig[0].validators
              .reduce((acc2, { type, value, message }) => {
                if (type === 'required')
                  return acc2[type](labels[message]);
                return acc2[type](value, labels[message]);
              }, Yup.string())
              .trim(),
          };
        }, {}),
      );

    const getInitialValues = () => {
      const { deviceSerial = '' } = parameters;
      return {
        deviceSerial,
      };
    };

    const initialValues = useMemo(() => getInitialValues(), []);

    const validationSchema = useMemo(
      () => getInputValidations(['deviceSerial'], tabs[0].fields),
      [],
    );
    const changeColumnLabels = () => {
      dataTableConfig.columns[1].display = `labels.deviceRegistration.tabs.device.columnTitle2.${deviceSearchType}`;
      dataTableConfig.columns[1].field = deviceSearchType;
      setDataTableConfig(dataTableConfig);
    };

    // Changes labels based on search type (lotNumber or serialNumber)
    useEffect(() => {
      const label = labels[tabs[0].fields[0].label[deviceSearchType]];
      const hint = labels[tabs[0].fields[0].hint[deviceSearchType]];
      setSearchLabel(label);
      setSearchHint(hint);
      setCanRegister(true);
      setApiError(false);
      setSearchPerformed(false);
      setTableData([]);
      setIsLotControlled(deviceSearchType === 'lotNumber');
    }, [deviceSearchType]);

    const deviceSearchGroup = findDevice.options.map(
      ({ value, label }) => ({
        id: `device_search_type_${value}`,
        label: (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <p onClick={() => setDeviceSearchType(value)}>
            {labels[label]}
          </p>
        ),
        value,
        checked: deviceSearchType === value,
      }),
    );

    dataTableConfig.events.onRowClick = (actions, row) => {
      if (!row.data?.clickable) {
        setCanRegister(false);
        return;
      }
      dataTableDetails.redirectToDeviceReview(row.data);
    };

    const refreshDeviceCache = async () => {
      const patientDevices = await patientService.getPatientDevices(
        providerId,
        professionalId,
        patientId,
        cacheControlHeader,
      );
      return patientDevices;
    };

    const { values, touched, errors, handleChange, handleSubmit } =
      useFormik({
        initialValues,
        onSubmit: async (submittedValues) => {
          setIsLoading(true);
          setCanRegister(true);
          setTableData([]);
          setSearchPerformed(false);
          setRefreshedCache(false);
          setTimeout(async () => {
            let devicesList = [];
            try {
              const devicesListResponse = await config.onSubmit(
                submittedValues,
                deviceSearchType,
              );
              if (devicesListResponse.apiResponse === '200') {
                if (devicesListResponse.registered) {
                  const patientDevices = await refreshDeviceCache();
                  const patientDeviceInCache =
                    patientDevices.devices.find(
                      (device) =>
                        device.serialNumber ===
                        submittedValues.deviceSerial,
                    ) ?? null;

                  if (patientDeviceInCache) {
                    setRefreshedCache(true);
                  }
                }
                if (
                  (devicesListResponse.deviceList[0]?.type ===
                    'Accessory' ||
                    devicesListResponse.deviceList[0]?.type ===
                      'Remote Assistant') &&
                  !hasExistingDevice
                ) {
                  setCanRegister(false);
                  setIsLoading(false);
                  return;
                }
                devicesList = devicesListResponse.deviceList.map(
                  (device) => {
                    const formattedDevice = {
                      ...device,
                      isLotControlled,
                    };

                    if (
                      !isLotControlled &&
                      formattedDevice.lotNumber
                    ) {
                      delete formattedDevice.lotNumber;
                    }

                    if (
                      isLotControlled &&
                      formattedDevice.serialNumber
                    ) {
                      delete formattedDevice.serialNumber;
                    }

                    formattedDevice.clickable = !(
                      !hasExistingDevice &&
                      (device.type === 'Accessory' ||
                        device.type === 'Remote Assistant')
                    );

                    return formattedDevice;
                  },
                );
                changeColumnLabels();
                setApiError(false);
                setTableData(devicesList);
                setSearchPerformed(true);
              } else {
                setIsLoading(false);
                setSearchPerformed(true);
                setApiError(true);
              }
            } catch (err) {
              log.error('Error fetching devices', err);
              setIsLoading(false);
              setSearchPerformed(true);
              setApiError(true);
            }
            setIsLoading(false);
          }, 100);
        },
        validationSchema,
      });

    const renderHeader = () => {
      return (
        <Modal.Header
          closeButton
          data-analytics="cancel_header_exit_register_device"
        >
          <Modal.Title>{modalConfig.modaltitle}</Modal.Title>
        </Modal.Header>
      );
    };

    labels.deviceAlreadyRegisteredRedirect = `${labels['labels.errorMessage.deviceAlreadyRegistered.message']}<br /><br />
    ${labels['labels.errorMessage.deviceAlreadyRegistered.message2']} 
    <a href="${patientLink}">${labels['labels.errorMessage.deviceAlreadyRegistered.link']}</a>`;

    const renderFooter = () => {
      return (
        <Modal.Footer>
          <Button
            text={modalConfig.yes}
            variant="brand-primary"
            onClick={() => modalConfig.onSuccess()}
            data-analytics={modalConfig.yesanalytics}
          />
          <Button
            text={modalConfig.no}
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
            data-analytics={modalConfig.noanalytics}
          />
        </Modal.Footer>
      );
    };

    return (
      <div className="ccl__container device-registration-wrapper">
        <div className="device-registration">
          <h6 className="device-registration__user-name">
            {patientNameFormatted}
          </h6>
          <PageHeader
            className="device-registration__title"
            title={{ content: labels[pageTitle] }}
          />
          <Tabs
            data={{
              tabs: tabs.map(({ id, title, disabled }) => ({
                id,
                label: labels[title],
                disabled,
                tooltip: disabled ? 'Unavailable' : undefined,
              })),
            }}
            config={{
              onChangeTab,
              initialTabIndex: tabIndex(),
              contentAsCard: true,
              fullWidth: false,
            }}
          >
            {/* TODO: Extract this to its own component */}
            <div style={{ padding: '50px 25px' }}>
              {hasExistingDevice && (
                <Alert show variant="brand">
                  {labels[infoMessageTab1]}
                </Alert>
              )}
              {!hasExistingDevice && (
                <Alert show>
                  {labels[infoMessageTab1]}
                  <br />
                  {labels[noExistingDevicesAlert]}
                </Alert>
              )}
              <form onSubmit={handleSubmit} onChange={onChange}>
                <RadioGroup
                  className="device-registration-find-by-radio-group"
                  fields={deviceSearchGroup}
                  label={labels[findDevice.title]}
                  name="searchType"
                  onChange={(e) =>
                    setDeviceSearchType(e.target.value)
                  }
                />
                <TextInput
                  className="ccl-l-device-registration-patient-tab__fields__device-serial"
                  name="deviceSerial"
                  value={values.deviceSerial}
                  label={searchLabel}
                  onChange={(e) => handleChange(e)}
                  error={
                    !!(touched.deviceSerial && errors.deviceSerial)
                  }
                  errorMsg={errors.deviceSerial}
                  hint={searchHint}
                />
                <Button
                  className="ccl-l-device-registration-patient-tab__fields__submit__button"
                  text={
                    isLoading ? (
                      <>
                        <div className="ccl-e-table--spinner">
                          {labels[searchingText]}
                          <div className="spinner-border" />
                        </div>
                      </>
                    ) : (
                      labels[searchText]
                    )
                  }
                  variant="digital-primary"
                  disabled={
                    errors.deviceSerial || !values.deviceSerial
                  }
                  onClick={handleSubmit}
                  progress={2}
                  data-analytics="search_register_device"
                />
              </form>
              <div className="ccl-l-device-registration-patient-tab__fields__clear" />
              {!canRegister && (
                <div className="ccl__data-table--device-registration ccl__data-table--dpx">
                  <Error
                    config={noExistingDevices.config}
                    labels={error.labels}
                    data={error.data}
                  />
                </div>
              )}
              {canRegister &&
                searchPerformed === true &&
                refreshedCache &&
                !apiError && (
                  <div className="ccl__data-table--device-registration ccl__data-table--dpx api-error">
                    <Error
                      config={{
                        ...deviceAlreadyRegistered.config,
                        text: 'deviceAlreadyRegisteredRedirect',
                      }}
                      labels={deviceAlreadyRegistered.labels}
                      data={deviceAlreadyRegistered.data}
                    />
                  </div>
                )}

              {canRegister && tableData.length > 0 && !apiError && (
                <div className="ccl__data-table--device-registration ccl__data-table--dpx">
                  <DataTable
                    config={dataTableConfig}
                    labels={dataTableDetails.labels}
                    data={tableData}
                  />
                </div>
              )}

              {canRegister &&
                searchPerformed === true &&
                tableData.length === 0 &&
                apiError === true &&
                !refreshedCache && (
                  <div className="ccl__data-table--device-registration ccl__data-table--dpx api-error">
                    <Error
                      config={errorApi.config}
                      labels={errorApi.labels}
                      data={errorApi.data}
                    />
                  </div>
                )}
              {canRegister &&
                searchPerformed === true &&
                apiError === false &&
                tableData.length === 0 &&
                !refreshedCache && (
                  <div className="ccl__data-table--device-registration ccl__data-table--dpx">
                    <Error
                      config={error.config}
                      labels={error.labels}
                      data={error.data}
                    />
                  </div>
                )}
            </div>

            <AccessoryRegistration
              labels={labels}
              tableConfig={dataTableConfig}
              tableDetails={dataTableDetails}
              error={error}
              errorApi={errorApi}
              noExistingDevices={noExistingDevices}
            />
          </Tabs>
        </div>
        <Button
          className="cancel-button"
          text="Cancel"
          variant="secondary"
          onClick={() => setShowModal(true)}
          progress={2}
          data-analytics="cancel_register_device"
        />
        <Modal
          {...modalConfig}
          onHide={handleClose}
          size={modalConfig.size}
          dialogClassName="device-registration-close"
          show={showModal}
        >
          {renderHeader()}

          <Modal.Body>
            <p />
            <Text
              className="ccl__no-results__isClinicPopup__subtitle"
              content={modalConfig.modalText}
              isHTML="true"
              type="small-body-text"
            />
          </Modal.Body>

          {renderFooter()}
        </Modal>
      </div>
    );
  },
);
