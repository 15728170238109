import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from '@cochlear-design-system/foundation';
import AsyncNotificationWidget from '../AsyncNotificationWidget';
import languageContent from '../../data/languageContent.json';
import providerService from '../../services/providerService';
import { routes } from '../../config';

const MainContentBlock = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [error, setError] = useState(query.get('error'));
  const [message, setMessage] = useState();

  useEffect(() => {
    if (error === 'provider') {
      setMessage(languageContent.en.mcpFeaturesError);
    }
  }, [error]);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          switch (e.data.pageEvent.type) {
            case 'providerError':
              if (e.data.pageEvent.target === 'dashboard') {
                providerService.updateProviderDetails(
                  'hasProviderError',
                  true,
                );
                setError('provider');
              }
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setError('');
    }, 30000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <div className="ccl__container dpx__main-content">
      {error && message && (
        <Alert
          actions={[]}
          dismissible
          heading=""
          onClose={() => {
            setError('');
          }}
          timestamp=""
        >
          {message}
        </Alert>
      )}
      <AsyncNotificationWidget />
    </div>
  );
};

export default MainContentBlock;
