import React, { useRef, useState, useEffect } from 'react';
import { Spinner, Alert } from '@cochlear-design-system/foundation';
import { routes, PROVIDER_DETAILS_COOKIE } from '../../config';
import cookieService from '../../services/cookieService';
import languageContent from '../../data/languageContent.json';

const CpnResource = () => {
  const resourcesUrl = routes.mcp.cpnResource;
  const resourceFrame = useRef();
  const [showResource, setShowResource] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const providerError = cookieService.getCookie(
    PROVIDER_DETAILS_COOKIE,
  ).hasProviderError;

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain || providerError) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          const mcpPageHeight = e.data.pageEvent.payload?.height;
          switch (e.data.pageEvent.type) {
            case 'pageLoaded':
              if (e.data.pageEvent.target === 'resources') {
                setShowSpinner(false);
                setShowResource(true);
                resourceFrame.current.style.height =
                  mcpPageHeight === 0
                    ? '1000px'
                    : `${mcpPageHeight}px`;
              }
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  return (
    <div>
      {providerError && (
        <Alert
          actions={[]}
          dismissible
          heading=""
          timestamp=""
          data-analytics="close_provider_error_alert"
        >
          {languageContent.en.mcpFeaturesError}
        </Alert>
      )}
      {!providerError && showSpinner && (
        <div className="spinner">
          <Spinner animation="border" />
        </div>
      )}

      <iframe
        src={resourcesUrl}
        id="cpn-resource-iframe"
        title="CPN Resource"
        width="100%"
        ref={resourceFrame}
        style={{
          visibility: showResource ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
};

export default CpnResource;
