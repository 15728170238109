import React, { useRef, useState, useEffect } from 'react';
import { Spinner, Alert } from '@cochlear-design-system/foundation';
import { routes, PROVIDER_DETAILS_COOKIE } from '../../config';
import { setIdpParam } from '../../utils/authprovider';
import cookieService from '../../services/cookieService';
import languageContent from '../../data/languageContent.json';

const ClinicEquipment = () => {
  const clinicEquipmentFrame = useRef();
  const [showClinicEquipment, setShowClinicEquipment] =
    useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const providerError = cookieService.getCookie(
    PROVIDER_DETAILS_COOKIE,
  ).hasProviderError;

  useEffect(() => {
    const handleEvent = () => {
      setShowSpinner(true);
      setShowClinicEquipment(false);
      if (clinicEquipmentFrame && clinicEquipmentFrame.current) {
        clinicEquipmentFrame.current.style.height = '100px';
      }
    };

    window.addEventListener('popstate', handleEvent);
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain || providerError) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          const mcpPageHeigth = e.data.pageEvent.payload?.height;
          switch (e.data.pageEvent.type) {
            case 'pageLoaded':
              if (
                e.data.pageEvent.target === 'clinicEquipment' ||
                e.data.pageEvent.target ===
                  'selectedServiceRequest' ||
                e.data.pageEvent.target === 'createServiceRequest'
              ) {
                if (
                  clinicEquipmentFrame &&
                  clinicEquipmentFrame.current
                ) {
                  setShowSpinner(false);
                  setShowClinicEquipment(true);
                  clinicEquipmentFrame.current.style.height =
                    mcpPageHeigth === 0
                      ? '1000px'
                      : `${mcpPageHeigth}px`;
                }
              }
              break;
            case 'loading':
              if (e.data.pageEvent.target === 'clinicEquipment') {
                setShowSpinner(true);
                setShowClinicEquipment(false);
                if (
                  clinicEquipmentFrame &&
                  clinicEquipmentFrame.current
                ) {
                  clinicEquipmentFrame.current.style.height = '100px';
                }
              }
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  return (
    <div>
      {providerError && (
        <Alert
          actions={[]}
          dismissible
          heading=""
          timestamp=""
          data-analytics="close_provider_error_alert"
        >
          {languageContent.en.mcpFeaturesError}
        </Alert>
      )}
      {!providerError && showSpinner && (
        <div className="spinner">
          <Spinner animation="border" />
        </div>
      )}
      <iframe
        src={setIdpParam(`${routes.mcp.clinicEquipment}`)}
        id="clinic-equipment-iframe"
        title="clinic-equipment"
        width="100%"
        ref={clinicEquipmentFrame}
        style={{
          visibility: showClinicEquipment ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
};

export default ClinicEquipment;
