import React from 'react';
import { EquipmentSummary } from '../EquipmentWrapper/EquipmentSummary';
import { ServiceRequest } from '../ServiceRequest/ServiceRequest';

export const getTabContent = (
  config,
  data,
  labels,
  leftData,
  rightData,
  unknownData,
  rcIframeKey,
) => {
  const children = [
    <EquipmentSummary
      config={config}
      data={data}
      labels={labels}
      leftData={leftData}
      rightData={rightData}
      unknownData={unknownData}
    />,
  ];

  if (config.serviceRequestConfig.enabled) {
    children.push(<ServiceRequest config={config} labels={labels} />);
  }

  if (config.remoteCheckConfig && config.remoteCheckConfig.enabled) {
    children.push(
      <div id="rc__container">
        <iframe
          src={config.remoteCheckConfig.remoteCheckUrl}
          title="Remote Check"
          id={config.remoteCheckConfig.id}
          key={rcIframeKey}
        />
      </div>,
    );
  }

  return children;
};
