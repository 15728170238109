export const config = (
  theme = '',
  isError = false,
  activeStep = 0,
  activeScenario = 'default-clinic',
  redirectToPatientRecord,
  deviceType,
  isLotControlled,
) => {
  return {
    formType: 'wizard',
    activeStep,
    activeScenario,
    maxSteps: 2,
    handleQueryParams: () => {},
    isLoading: false,
    errorFormView: '',
    isError: false,
    errorMode: '',
    theme,

    tabsData: {
      accountId: 'ba4af081-697b-4d02-8d89-f70942657843',
      userId: 1,
      labels: {
        promptText: 'Select a recipient...',
        promptTextAbbrv: 'recipient...',
        type: 'recipient',
      },
      tabs: [
        {
          firstName: 'Carer1CeliaMcGowanUS',
          lastName: 'Carer1CeliaMcGowanUS',
          tabIndex: 0,
          type: 'Carer',
          userId:
            'bb7067195a6ea16b9dccee5435be47bdb540f656c5b9ce9ca692d8b5536e0455HIhEjwH%2FYH2Gf1Lc92Sk5w%3D%3D6aeadadb4a244de2c4b2c2fb3b4b41a75289a491b911ac9ef1ad9257b2848561',
        },
      ],
    },

    steps: [
      {},
      {
        continue: {
          labels: {
            defaultText: 'labels.deviceRegReview.register',
            inProcessText: 'labels.createPatient.continue',
            ProccessedText: 'labels.createPatient.continue',
          },
          updateState: () => {
            return 'hidden';
          },

          onProcess: async () => {},
          onAfterProcess: () => {},
          analytics: 'register_new_success_register_device',
        },

        cancel: {
          labels: {
            defaultText: 'labels.deviceRegReview.close',
            inProcessText: 'labels.deviceRegReview.close',
            ProccessedText: 'labels.deviceRegReview.close',
          },

          onProcess: () => {
            redirectToPatientRecord();
          },
          onAfterProcess: () => {},
          updateState: () => {},
          analytics: 'close_success_register_device',
        },
        conditions: [
          {
            name: 'success-page',
            views: [
              {
                country: 'US',
                language: 'en',
                mask: 'mmmm dd yyyy',
                id: 'personalView',
                title: 'labels.deviceRegReview.device.titlePatient',
                fallbackTitle: 'labels.deviceRegReview.content',
                isError,
                type: 'Form',
                mode: 'read',
                data: 'personal',
                editLabel: 'labels.deviceRegReview.device.edit',
                read: {
                  name: 'read',
                  clkErrorLabel: 'labels.af.personal.read.error.clk',
                  errorLabel: 'labels.af.personal.read.error.label',
                  fieldLayout: 'horizontal',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  fields: [],

                  elements: [
                    {
                      id: 'name',
                      label: 'labels.deviceRegReview.device.name',
                      value: '{{patientName}}',
                    },
                    {
                      id: 'dob',
                      label: 'labels.deviceRegReview.device.dob',
                      value: '{{patientDob}}',
                    },
                  ],
                },
                edit: {
                  saveLabel: 'labels.af.buttons.buttonBar.save',
                  cancelLabel: 'labels.af.buttons.buttonBar.cancel',
                  savingLabel: 'labels.af.buttons.buttonBar.saving',
                  savedLabel: 'labels.af.buttons.buttonBar.saved',
                  edit: 'labels.af.actionBar.buttons.edit',
                  cancel: 'labels.af.actionBar.buttons.cancel',
                  title: 'labels.af.title.personal',
                  fallbackTitle: 'labels.af.title.personal2',
                  mask: 'dd MM yyyy',
                  useLiveValidation: false,
                  showInitialErrors: false,
                  elements: [],
                },
              },
              {
                country: 'US',
                language: 'en',
                mask: 'mmmm dd yyyy',
                id: 'personalView',
                title: 'labels.deviceRegReview.content',
                fallbackTitle:
                  'labels.deviceRegReview.device.titleDevice',
                isError,
                type: 'Form',
                mode: 'read',
                data: 'personal',
                editLabel: 'labels.deviceRegReview.device.edit',
                read: {
                  name: 'read',
                  clkErrorLabel: 'labels.af.personal.read.error.clk',
                  errorLabel: 'labels.af.personal.read.error.label',
                  fieldLayout: 'horizontal',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  fields: [],

                  elements: [
                    {
                      id: 'productFamily',
                      label:
                        'labels.deviceRegReview.device.productFamily',
                      value: '{{productFamily}}',
                    },
                    {
                      id: 'deviceType',
                      label:
                        'labels.deviceRegReview.device.deviceType',
                      value: '{{deviceType}}',
                    },
                    {
                      id: 'model',
                      label: 'labels.deviceRegReview.device.model',
                      value: '{{model}}',
                    },
                    {
                      id: 'description',
                      label:
                        'labels.deviceRegReview.device.description',
                      value: '{{description}}',
                    },
                    {
                      id: 'serialNumber',
                      label:
                        'labels.deviceRegReview.device.serialNumber',
                      value: '{{serialNumber}}',
                      hidden: isLotControlled,
                    },
                    {
                      id: 'lotNumber',
                      label:
                        'labels.deviceRegReview.device.lotNumber',
                      value: '{{lotNumber}}',
                      hidden: !isLotControlled,
                    },
                  ],
                },
              },
              {
                country: 'US',
                language: 'en',
                mask: 'dd mmm yyyy',
                id: 'personalView',
                title:
                  deviceType === 'SPEECH_PROCESSOR'
                    ? 'labels.deviceRegReview.fitting'
                    : 'labels.deviceRegReview.device.titleSurgery',
                fallbackTitle: 'labels.deviceRegReview.content',
                isError,
                type: 'Form',
                mode: 'read',
                data: 'personal',
                editLabel: 'labels.deviceRegReview.device.edit',
                read: {
                  name: 'read',
                  clkErrorLabel: 'labels.af.personal.read.error.clk',
                  errorLabel: 'labels.af.personal.read.error.label',
                  fieldLayout: 'horizontal',
                  mask: 'dd mmm yyyy',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  fields: [],

                  elements: [
                    {
                      id: 'surgeryDate',
                      label:
                        deviceType === 'SPEECH_PROCESSOR'
                          ? 'labels.deviceRegReview.device.fittingDate'
                          : 'labels.deviceRegReview.device.surgeryDate',
                      value: '{{datePickerFormatted}}',
                    },
                    {
                      id: 'earSide',
                      label: 'labels.deviceRegReview.device.earSide',
                      value: '{{earSide}}',
                    },
                    {
                      id: 'surgeon',
                      label:
                        deviceType === 'SPEECH_PROCESSOR'
                          ? 'labels.deviceRegReview.device.audiologist'
                          : 'labels.deviceRegReview.device.surgeon',
                      value: '{{surgeon}}',
                    },
                    {
                      id: 'surgeryCentre',
                      label:
                        deviceType === 'SPEECH_PROCESSOR'
                          ? 'labels.deviceRegReview.fittingClinic'
                          : 'labels.deviceRegReview.device.surgeryCentre',
                      value: '{{clinicName}}, {{clinicAddress}}',
                    },
                  ],
                },
              },
            ],
          },
          {
            name: 'success-page-accessory',
            views: [
              {
                country: 'US',
                language: 'en',
                mask: 'mmmm dd yyyy',
                id: 'personalView',
                title: 'labels.deviceRegReview.device.titlePatient',
                fallbackTitle: 'labels.deviceRegReview.content',
                isError,
                type: 'Form',
                mode: 'read',
                data: 'personal',
                editLabel: 'labels.deviceRegReview.device.edit',
                read: {
                  name: 'read',
                  clkErrorLabel: 'labels.af.personal.read.error.clk',
                  errorLabel: 'labels.af.personal.read.error.label',
                  fieldLayout: 'horizontal',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  fields: [],

                  elements: [
                    {
                      id: 'name',
                      label: 'labels.deviceRegReview.device.name',
                      value: '{{patientName}}',
                    },
                    {
                      id: 'dob',
                      label: 'labels.deviceRegReview.device.dob',
                      value: '{{patientDob}}',
                    },
                  ],
                },
                edit: {
                  saveLabel: 'labels.af.buttons.buttonBar.save',
                  cancelLabel: 'labels.af.buttons.buttonBar.cancel',
                  savingLabel: 'labels.af.buttons.buttonBar.saving',
                  savedLabel: 'labels.af.buttons.buttonBar.saved',
                  edit: 'labels.af.actionBar.buttons.edit',
                  cancel: 'labels.af.actionBar.buttons.cancel',
                  title: 'labels.af.title.personal',
                  fallbackTitle: 'labels.af.title.personal2',
                  mask: 'dd MM yyyy',
                  useLiveValidation: false,
                  showInitialErrors: false,
                  elements: [],
                },
              },
              {
                country: 'US',
                language: 'en',
                mask: 'mmmm dd yyyy',
                id: 'personalView',
                title: 'labels.deviceRegReview.accessoryContent',
                fallbackTitle:
                  'labels.deviceRegReview.device.titleDevice',
                isError,
                type: 'Form',
                mode: 'read',
                data: 'personal',
                editLabel: 'labels.deviceRegReview.device.edit',
                read: {
                  name: 'read',
                  clkErrorLabel: 'labels.af.personal.read.error.clk',
                  errorLabel: 'labels.af.personal.read.error.label',
                  fieldLayout: 'horizontal',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  fields: [],

                  elements: [
                    {
                      id: 'deviceType',
                      label:
                        'labels.deviceRegReview.device.deviceType',
                      value: '{{deviceType}}',
                    },
                    {
                      id: 'description',
                      label:
                        'labels.deviceRegReview.device.description',
                      value: '{{description}}',
                    },
                    {
                      id: 'productFamily',
                      label:
                        'labels.deviceRegReview.device.partNumber',
                      value: '{{partNumber}}',
                    },
                    {
                      id: 'purchaseDate',
                      label:
                        'labels.deviceRegReview.device.purchaseDate',
                      value: '{{datePickerFormatted}}',
                    },
                    {
                      id: 'serialNumber',
                      label:
                        'labels.deviceRegReview.device.serialNumber',
                      value: '{{serialNumber}}',
                      hidden: isLotControlled,
                    },
                    {
                      id: 'lotNumber',
                      label:
                        'labels.deviceRegReview.device.lotNumber',
                      value: '{{lotNumber}}',
                      hidden: !isLotControlled,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    ],

    errorView: {
      codeLabel: 'Error code',
      errorResponse: '',
      mode: '',
      data: {
        submit: {
          image: {
            src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
            alt: 'Bug.svg',
            width: '120',
          },
          title: "That's strange, it seems something went wrong.",
          text: 'We had a problem updating your details.<br />If you need immediate help, contact our customer support team on <a href="tel:+1-877-651-7001" class="ccl__text--interactive-text" data-nw-error-link="phoneNumber" data-nw-action="call">877-651-7001.</a>',
          buttons: [
            {
              name: 'tryAgain',
              variant: 'brand-primary',
              text: 'Try again',
              icon: '',
              link: '',
              action: 'retry-submit',
              useCallback: true,
            },
            {
              name: 'backToHome',
              variant: 'secondary',
              text: 'Cochlear home',
              icon: 'chevron-right',
              link: 'https://node.sit.cms.cochlear.cloud/us/en/home',
              action: 'link-cochlear-home',
              useCallback: false,
            },
          ],
        },
        read: {
          image: {
            src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
            alt: 'Bug.svg',
            width: '120',
          },
          title: "That's strange, it seems something went wrong.",
          text: 'We’re working to fix the problem and we’ll be up and running soon.<br />If you need immediate help, contact our customer support team on <a href="tel:+1-877-651-7001" class="ccl__text--interactive-text" data-nw-error-link="phoneNumber" data-nw-action="call">877-651-7001.</a>',
          buttons: [
            {
              name: 'backToHome',
              variant: 'secondary',
              text: 'Cochlear home',
              icon: 'chevron-right',
              link: '/us/en/home',
              action: 'link-cochlear-home',
              useCallback: false,
            },
          ],
        },
        readWrite: {
          image: {
            src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
            alt: 'Bug.svg',
            width: '120',
          },
          title: "That's strange, it seems something went wrong.",
          text: 'We’re working to fix the problem and we’ll be up and running soon.<br />If you need immediate help, contact our customer support team on <a href="tel:+1-877-651-7001" class="ccl__text--interactive-text" data-nw-error-link="phoneNumber" data-nw-action="call">877-651-7001.</a>',
          buttons: [
            {
              name: 'backToHome',
              variant: 'secondary',
              text: 'Cochlear home',
              icon: 'chevron-right',
              link: '/us/en/home',
              action: 'link-cochlear-home',
              useCallback: false,
            },
          ],
        },
      },
    },
    token: '',
    popOverPrompt: {
      showPopOverPrompt: false,
      popOverPromptText: '',
      storage: {
        check: 'exists',
        method: 'session',
        name: 'carerPrompt',
        value: 1,
      },
    },
  };
};
/* ignore jslint end */

export const alertConfig = (
  redirectToDeviceRegistration,
  redirectToPatientRecord,
  labels,
) => {
  return {
    actions: [
      {
        text: labels['labels.deviceRegReview.alertConfig.printText'],
        onClick: () => {
          window.print();
        },
      },
      {
        text: labels[
          'labels.deviceRegReview.alertConfig.goToPatientRecord'
        ],
        onClick: () => {
          redirectToPatientRecord();
        },
      },
      {
        text: labels[
          'labels.deviceRegReview.alertConfig.registerAnother'
        ],
        onClick: () => {
          redirectToDeviceRegistration();
        },
      },
    ],
    title: `${labels['labels.deviceRegReview.alertConfig.registrationProgress']}\n${labels['labels.common.alert.requestSubmitted']}`,
  };
};
