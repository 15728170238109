import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { PatientsWrapper } from '../../ui/PatientsWrapper/PatientsWrapper.js';
import providerService from '../../services/providerService';
import featureFlagService from '../../services/featureFlagService';
import { getSimpleSearchConfig } from '../Search/searchConfig.js';
import { getPatientsTableConfig } from './patientsConfig';
import { errorConfig } from './errorConfig';
import { patientData } from './dataMapper';
import { routes } from '../../config';
import { getLabelsForFields } from '../../utils/labelHelper';
import tokenRenewalService from '../../services/tokenRenewalService.js';

const queryStrings = () => {
  return new URLSearchParams(useLocation().search);
};

const Patients = ({ rendering, country, lang }) => {
  const labels = getLabelsForFields(rendering.fields);
  const history = useHistory();
  const featureFlags = featureFlagService.getFeatureFlags();

  const { providerId } = providerService.getProviderDetails();
  const tempData = [];
  const [searchTerm, setSearchTerm] = useState(
    queryStrings().get('q'),
  );
  const [searchType, setSearchType] = useState(
    queryStrings().get('searchType'),
  );
  const [tableData, setTableData] = useState(tempData);
  const [renderState, setRenderState] = useState();
  const [hasError, setHasError] = useState(false);

  const hasAdvancedSearch = featureFlags.advancedSearch;

  const getPathPrefix = () => {
    return `/${country}/${lang}`;
  };

  const onSearch = (values) => {
    history.push(
      `${getPathPrefix()}/${routes.dpx.patient}?${new URLSearchParams(
        values,
      ).toString()}`,
    );
  };

  const onAdvancedSearch = () =>
    history.push(`${getPathPrefix()}/${routes.dpx.advancedSearch}`);

  const searchConfig = getSimpleSearchConfig(
    onSearch,
    onAdvancedSearch,
    hasAdvancedSearch,
  );

  const handleNameClick = (patientId) => {
    const patients = tableData.filter(
      (patient) => patient.name.id === patientId,
    );
    const patient = patients?.length ? patients[0] : null;

    history.push({
      pathname: `${getPathPrefix()}/${routes.dpx.patientDetails}`,
      search: `?patientId=${patientId}`,
      state: {
        isDeceased: patient?.isDeceased,
      },
    });
  };

  const handleLocatePatient = () =>
    history.push(`${getPathPrefix()}/${routes.dpx.advancedSearch}`);

  const tableConfig = getPatientsTableConfig(
    handleNameClick,
    handleLocatePatient,
  );

  const searchData = {
    greetings: '',
    initialValues: {
      q: queryStrings().get('q') || '',
      searchType: queryStrings().get('searchType') || 'patient',
    },
  };

  const errorDetails = {
    config: errorConfig,
    labels,
    data: {},
  };
  const { pageSize } = tableConfig.lazyLoadOptions;
  let response = null;

  // patient search API call
  const fetchPatients = async (page) => {
    const searchParams = { name: searchTerm };
    response = await providerService.getSearchResult(
      providerId,
      searchParams,
      page,
      pageSize,
    );

    setHasError(false);

    if (response?.status !== 200) {
      setHasError(true);
      setSearchTerm('');
      if ([403, 401].includes(response?.status)) {
        // authorization issue, check token to see if  it's still valid
        const timeToExpiry =
          tokenRenewalService.getTokenTimeToExpiry();
        if (!timeToExpiry || timeToExpiry < 0) {
          // token has expired. Log the user out
          window.location.href = routes.dpx.logout;
        }
      }
    }

    const tableDataFormatted = patientData(response.data);

    if (tableDataFormatted?.length === 0 && page === 0) {
      setRenderState('noresults');
    } else {
      setTableData(tableDataFormatted);
      setRenderState('loaded');
    }
  };

  // if searchTerm changes, make a new API call
  useEffect(() => {
    if (searchTerm && searchType) {
      history.push(
        `${getPathPrefix()}/${
          routes.dpx.patient
        }?q=${searchTerm}&searchType=${searchType}`,
      );

      setRenderState('loading');
      fetchPatients(0);
    }
  }, [searchTerm, searchType]);

  tableConfig.events = {
    onLoadMore: (page) => {
      fetchPatients(page);
    },
  };

  return (
    <PatientsWrapper
      search={{ config: searchConfig, data: searchData, labels }}
      table={{ config: tableConfig, data: [], labels }}
      tableData={tableData}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchType={searchType}
      setsetSearchType={setSearchType}
      renderState={renderState}
      error={errorDetails}
      hasError={hasError}
    />
  );
};

export default Patients;
