import { routes } from '../../config';
import { logger } from '../../logger';

const log = logger();

function isScriptLoaded(src, doc) {
  const scripts = doc.getElementsByTagName('script');
  for (let i = 0; i < scripts.length; i += 1) {
    if (scripts[i].getAttribute('src') === src) {
      return true;
    }
  }
  return false;
}

/**
 * Insert Remote Check script into the rcbridge DOM
 */
export const insertRemoteCheckScript = () => {
  log.info('###insertRemoteCheckScript');
  const scriptSrc = routes.remoteCheckScriptPath;
  const rcBridgeIFrame = document.getElementById('rcbridge');
  const rcBridgeIFrameDocument =
    rcBridgeIFrame.contentDocument ||
    rcBridgeIFrame.contentWindow.document;

  if (!isScriptLoaded(scriptSrc, rcBridgeIFrameDocument)) {
    const rcScript = document.createElement('script');
    rcScript.src = scriptSrc;
    rcBridgeIFrameDocument
      .querySelector('body')
      .appendChild(rcScript);
  }
};

/**
 * Setup Remote Check variables and insert into rcbridge
 * @param {object} patientDetails
 * @param {array} carers
 * @param {string} providerId
 * @param {string} providerName
 * @param {string} lang
 * @param {string} locale
 * @param {boolean} syncedToSf
 * @param {int} registrationTid
 * @param {function} getAge
 */

export const setupRemoteCheck = (
  patientDetails,
  carers,
  providerId,
  providerName,
  lang,
  localeFromMCP,
  syncedToSf,
  registrationTid,
  getAge,
  hasLoginFromMCP,
  ageThresholdFromMCP,
) => {
  const age = getAge(patientDetails.data.dateOfBirth);
  const {
    email,
    firstName,
    lastName,
    isDeceased,
    accountStatus,
    isUnderAge,
    // eslint-disable-next-line no-unused-vars
    personas,
  } = patientDetails.data;
  const deceased = isDeceased === true;
  const hasLogin =
    accountStatus?.toLowerCase() === 'active' ||
    hasLoginFromMCP === 'true';
  let carersList = [];
  const carersWithLogin = [];

  if (carers !== null && carers.length > 0) {
    carersList = carers.map((carer) => {
      let carerHasLogin = false;

      if (carer.data.accountStatus !== null) {
        carerHasLogin =
          carer.data.accountStatus?.toLowerCase() === 'active';
        if (carerHasLogin) {
          // carersWithLogin.push(carer.data.id); // need partyId
        }
      }

      return {
        cochlearId: carer.data.id,
        email: carer.data.email,
        first: carer.data.firstName,
        hasLogin: carerHasLogin,
        last: carer.data.lastName,
        partyId: '', // blank for now
        cochlearAccountStatus:
          carer.data.accountStatus?.toLowerCase(),
      };
    });
  }

  const mcpValues = {
    cId: providerId,
    rId: patientDetails.data.id,
    hasClinicRelationship: true,
    syncedToSf: true, // default to true in all cases
    clinicName: providerName,
    userLocale: localeFromMCP,
    lang,
    ageThreshold: ageThresholdFromMCP,
    carers: carersList,
    carersWithLogin,
    recipient: {
      email,
      first: firstName,
      last: lastName,
      deceased,
      hasLogin,
      age,
      registrationTid,
      emailId: '0',
      underAge: isUnderAge,
      cochlearAccountStatus: accountStatus?.toLowerCase(),
    },
  };

  log.debug('###mcpValues', mcpValues);

  const rcBridgeIFrame = document.getElementById('rcbridge');
  rcBridgeIFrame.contentWindow.MCP.model = mcpValues;
};

/**
 * Attach listener to listen for postmessages from rcbridge to
 * 1. make styling changes when opening and closing the modal
 * 2. redirect to Remote Check url
 */
export const attachRemoteCheckMessageListener = () => {
  // eslint-disable-next-line func-names
  window.addEventListener('message', function (e) {
    if (e.origin !== window.location.origin) return;

    const backDrop = document.getElementById('backdrop');
    const rcBridge = document.getElementById('rcbridge');

    if (typeof e.data === 'object') {
      if ('modalEvent' in e.data) {
        // this listener is attached before we know the patient status.
        // deceased patients have that iframe removed after the listener is added.
        // check, if we still have the elements to access
        if (
          !rcBridge?.contentWindow ||
          !backDrop?.style ||
          !rcBridge.style
        )
          return;

        switch (e.data.modalEvent.target) {
          case 'remoteCheck':
            if (e.data.modalEvent.type === 'open') {
              backDrop.style.zIndex = '2';
              backDrop.style.backgroundColor = '#000';
              rcBridge.style.left = '-200px';
              rcBridge.style.height = '700px';
              rcBridge.contentWindow.document.getElementById(
                'EnrolmentStatusBar',
              ).style.display = 'none';
              rcBridge.contentWindow.document.getElementById(
                'remote-check-iframe',
              ).style.display = 'none';
              rcBridge.contentWindow.document.getElementsByClassName(
                'modal-backdrop',
              )[0].style.backgroundColor = 'transparent';
            }
            if (e.data.modalEvent.type === 'close') {
              backDrop.style.zIndex = '0';
              backDrop.style.backgroundColor = '#fff';
              rcBridge.style.left = '15px';
              rcBridge.contentWindow.document.getElementById(
                'EnrolmentStatusBar',
              ).style.display = 'block';
            }
            break;
          default:
            break;
        }
      }
      if ('pageEvent' in e.data) {
        switch (e.data.pageEvent.type) {
          case 'remoteCheckRedirectUrl':
            window.location.href = `${e.data.pageEvent.payload.url}&theme=dpx`;
            break;
          default:
            break;
        }
      }
    }
  });
};
