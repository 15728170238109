import React, { useRef, useState, useEffect } from 'react';
import { Spinner, Alert } from '@cochlear-design-system/foundation';
import { routes, PROVIDER_DETAILS_COOKIE } from '../../config';
import { setIdpParam } from '../../utils/authprovider';
import cookieService from '../../services/cookieService';
import languageContent from '../../data/languageContent.json';

const ClinicianManagement = () => {
  const clinicianManagementFrame = useRef();
  const [showClinicianManagement, setShowClinicianManagement] =
    useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const providerError = cookieService.getCookie(
    PROVIDER_DETAILS_COOKIE,
  ).hasProviderError;

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain || providerError) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          const mcpPageHeigth = e.data.pageEvent.payload?.height;
          switch (e.data.pageEvent.type) {
            case 'pageLoaded':
              if (e.data.pageEvent.target === 'clinicManagement') {
                setShowSpinner(false);
                setShowClinicianManagement(true);
                clinicianManagementFrame.current.style.height =
                  mcpPageHeigth === 0
                    ? '1000px'
                    : `${mcpPageHeigth}px`;
              }
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  return (
    <div>
      {providerError && (
        <Alert
          actions={[]}
          dismissible
          heading=""
          timestamp=""
          data-analytics="close_provider_error_alert"
        >
          {languageContent.en.mcpFeaturesError}
        </Alert>
      )}
      {!providerError && showSpinner && (
        <div className="spinner">
          <Spinner animation="border" />
        </div>
      )}
      <iframe
        src={setIdpParam(`${routes.mcp.clinicManagement}`)}
        id="clinician-management-iframe"
        title="clinician-management"
        width="100%"
        ref={clinicianManagementFrame}
        style={{
          visibility: showClinicianManagement ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
};

export default ClinicianManagement;
