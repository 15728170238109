/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Text,
  Button,
  Alert,
} from '@cochlear-design-system/foundation';
import { Feature } from 'flagged';
import { AdvancedSearch } from '../../ui/AdvancedSearch/index.js';
import Spinner from '../Spinner';
import providerService from '../../services/providerService';
import patientService from '../../services/patientService.js';
import { getConfig } from './locatePatientConfig';
import {
  formatDate,
  getDateInputFormat,
} from '../../utils/dateTimeFns';
import DisabledFeature from '../DisabledFeature/index.js';
import { routes } from '../../config.js';
import { getLabelsForFields } from '../../utils/labelHelper';
import notificationStatus from '../../actions/notificationStatusAction';
import { NOTIFICATION_IS_QUEUED } from '../../actions/types';
import { logger } from '../../logger';
import { getMergedAddress } from '../../utils/addressHelper';

const log = logger();

const LocatePatient = ({ rendering, country, lang }) => {
  const labels = getLabelsForFields(rendering.fields);
  const userCountry = country.toUpperCase();
  const history = useHistory();
  const providerDetails = providerService.getCurrentProvider();
  const providerCountry = providerDetails.address?.countryCode;
  const { format: dateInputFormat, hint: dateHint } =
    getDateInputFormat(providerCountry);

  const dispatch = useDispatch();

  const initialData = {
    searchType: 'patients',
    parameters: {},
    tableData: [],
    renderState: 'loading',
  };

  const [data, setData] = useState(initialData);
  const [submitted, setSubmitted] = useState(false);
  const [clinicData, setClinicData] = useState([]);
  const [nameClicked, setNameClicked] = useState(false);
  const [agreeClicked, setAgreeClicked] = useState(false);
  const [searchParameters, setSearchParameters] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [upliftTimeout, setUpliftTimeout] = useState(false);
  const [showProvisionalAlert, setShowProvisionalAlert] =
    useState(false);
  const provisionalAlertHeading =
    labels['labels.common.accessPatientRecordNotPossible'];
  const [provisionalAlertText, setProvisionalAlertText] = useState();
  const [upliftRequestId, setUpliftRequestId] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const searchClinics = async (searchParam) => {
    const countryCode = searchParam.country || userCountry;
    const clinics = await providerService.searchClinics(
      countryCode,
      searchParam.clinic,
    );
    if (clinics.providers && clinics.providers.length > 0) {
      clinics.providers.forEach((provider) => {
        provider.address = getMergedAddress(provider);
      });
    }
    return clinics.providers;
  };

  const selectClinic = (action, params) => {
    const selectedClinicData = [];
    selectedClinicData.push({
      clinicId: params.data.id,
      name: params.data.name,
      address: params.data.address,
      isRemovable: true,
    });
    setClinicData(selectedClinicData);
  };

  const createPatient = () =>
    history.push(
      `/${country}/${lang}/${routes.dpx.createPatientLanding}`,
    );
  const contactCustomerService = () =>
    window.open(routes.customerService, '_blank');

  const viewPatientRecord = () => {
    setNameClicked(true);
  };

  const agreementModalClose = () => {
    const configCopy = { ...config };
    configCopy.tabs[0].agreementModalConfig.visible = false;
    setConfig(configCopy);
    setNameClicked(false);
  };
  const agreementModalSubmit = () => {
    setNameClicked(false);
    setAgreeClicked(true);
  };

  const [config, setConfig] = useState(
    getConfig(
      searchClinics,
      selectClinic,
      createPatient,
      contactCustomerService,
      viewPatientRecord,
      agreementModalClose,
      agreementModalSubmit,
      userCountry,
      dateInputFormat,
      dateHint,
      labels,
    ),
  );

  useEffect(async () => {
    if (nameClicked) {
      setAgreeClicked(false);
      const patient = data.tableData[0];
      const patientId = patient.name.id;
      const professionalProviderId =
        searchParameters.relatedProfessionalProviderId;

      if (patient.persona === 'Provisional-Unknown') {
        setShowProvisionalAlert(true);
        setProvisionalAlertText(
          labels['labels.locatePatient.provisionalUnknownAlertText'],
        );
      } else if (patient.persona === 'Provisional-Requester') {
        setShowProvisionalAlert(true);
        setProvisionalAlertText(
          labels['labels.common.contactCustomerServiceAssistance'],
        );
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!patient.isRelatedToProvider) {
          const configCopy = { ...config };
          configCopy.tabs[0].agreementModalConfig.visible = true;
          setConfig(configCopy);
        } else {
          // eslint-disable-next-line no-lonely-if
          if (patient.persona === 'Provisional-Recipient') {
            setShowSpinner(true);
            const organisationType = providerDetails.isDistributor
              ? 'Distributor'
              : 'Provider';
            const result = await patientService.upliftPatient(
              professionalProviderId,
              patientId,
              professionalProviderId,
              organisationType,
              searchParameters.firstName,
              searchParameters.lastName,
            );
            if (!result) {
              history.push({
                pathname: `/${country}/${lang}/${routes.dpx.error}`,
              });
              return;
            }
            dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));
            setUpliftRequestId(result.upliftStatus.requestId);
          } else {
            history.push({
              pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
              search: `?patientId=${patientId}`,
            });
          }
        }
      }
    }
  }, [nameClicked]);

  useEffect(async () => {
    if (agreeClicked) {
      // associate patient with clinic
      const patient = data.tableData[0];
      const patientId = patient.name.id;
      const professionalProviderId =
        searchParameters.relatedProfessionalProviderId;
      const organisationType = providerDetails.isDistributor
        ? 'Distributor'
        : 'Provider';

      if (
        patient.isProvisional &&
        patient.persona === 'Provisional-Recipient'
      ) {
        setShowSpinner(true);
        window.scrollTo(0, 0);
        const result = await patientService.upliftPatient(
          professionalProviderId,
          patientId,
          professionalProviderId,
          organisationType,
          searchParameters.firstName,
          searchParameters.lastName,
        );
        if (!result) {
          history.push({
            pathname: `/${country}/${lang}/${routes.dpx.error}`,
          });
          return;
        }
        dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));

        setUpliftRequestId(result.upliftStatus.requestId);
      } else {
        // associate
        const associateClinicsResult =
          await patientService.associateWithClinic(
            patientId,
            professionalProviderId,
            organisationType,
          );
        if (!associateClinicsResult) {
          history.push({
            pathname: `/${country}/${lang}/${routes.dpx.error}`,
          });
          return;
        }
        history.push({
          pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
          search: `?patientId=${patientId}`,
        });
      }
    }
  }, [agreeClicked]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const startPolling = async () => {
      const patient = data.tableData[0];
      const patientId = patient.name.id;
      const professionalProviderId =
        searchParameters.relatedProfessionalProviderId;
      const status = await patientService.pollUpliftStatus(
        professionalProviderId,
        patientId,
        upliftRequestId,
        source.token,
      );
      if (status === 'processed') {
        history.push({
          pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
          search: `?patientId=${data.tableData[0].name.id}`,
        });
      } else {
        setUpliftTimeout(true);
      }
    };

    if (upliftRequestId) {
      startPolling();
    }

    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, [upliftRequestId]);

  useEffect(() => {
    if (submitted) {
      const configCopy = { ...config };
      configCopy.tabs[0].hasError = false;
      setConfig(configCopy);

      const dataCopy = { ...data };
      dataCopy.renderState = 'loading';
      setData(dataCopy);
    }
  }, [submitted]);

  data.selectedClinicData = clinicData;

  const handleSubmit = async ({ parameters }) => {
    setNameClicked(false);
    setShowProvisionalAlert(false);
    const { patientIdentifier } = parameters;

    switch (patientIdentifier) {
      case 'device-serial':
        delete parameters.accountUsername;
        delete parameters.associatedClinic;
        break;
      case 'account-username':
        delete parameters.deviceSerial;
        delete parameters.associatedClinic;
        break;
      case 'associated-clinic':
        delete parameters.accountUsername;
        delete parameters.deviceSerial;
        break;
      default:
        break;
    }
    setSubmitted(true);

    const dataCopy = { ...data };

    const toTrim = [
      'firstName',
      'lastName',
      'deviceSerial',
      'accountUsername',
    ];

    Object.keys(parameters).forEach((key) => {
      if (
        toTrim.includes(key) &&
        typeof parameters[key] === 'string'
      ) {
        parameters[key] = parameters[key].trim();
      }
    });

    if (parameters.accountUsername) {
      parameters.userName = parameters.accountUsername;
      delete parameters.accountUsername;
    }

    if (parameters.associatedClinic) {
      parameters.providerCochlearId = String(
        parameters.associatedClinic,
      );
      delete parameters.associatedClinic;
    }

    if (parameters.deviceSerial) {
      parameters.deviceSerialNumber = parameters.deviceSerial;
      delete parameters.deviceSerial;
    }

    const configCopy = { ...config };

    delete parameters.patientIdentifier;
    delete parameters.undefined;

    parameters.relatedProfessionalProviderId = providerDetails.id;

    setSearchParameters(parameters);

    let result = null;

    try {
      setSubmitDisabled(true);
      result = await providerService.locatePatient(parameters);

      const patientCount = result?.metadata?.pagination?.totalItems;

      setSubmitDisabled(false);
      const patient = result.patients;
      let patientsTransformed = [];

      if (patientCount === 1) {
        patientsTransformed = [
          {
            dob: formatDate(patient[0].dateOfBirth),
            name: {
              item: `${patient[0].firstName} ${patient[0].lastName}`,
              id: patient[0].cochlearId,
            },
            cochlearId: patient[0].cochlearId,
            email: patient[0].email,
            isRelatedToProvider: patient[0].isRelatedToProvider,
            isProvisional: patient[0].persona.includes('Provisional'),
            persona: patient[0].persona,
            phone: patient[0].phone,
          },
        ];
      } else if (patientCount > 1) {
        patientsTransformed = patient.map(() => {
          return {};
        });
      }

      dataCopy.tableData = patientsTransformed;
      dataCopy.patientCount = patientCount;
      configCopy.tabs[0].patientResultTableConfig.multipleResults = false;

      switch (patientCount) {
        case 0:
          dataCopy.renderState = 'noresults';
          break;
        case 1:
          dataCopy.renderState = 'loaded';
          break;
        default:
          dataCopy.renderState = 'loaded';
          configCopy.tabs[0].patientResultTableConfig.multipleResults = true;
          break;
      }
      setConfig(configCopy);
      setData(dataCopy);
    } catch (error) {
      log.error('Error locating patient', error);
      configCopy.tabs[0].hasError = true;
      setConfig(configCopy);
    } finally {
      setSubmitted(false);
    }
  };

  config.onSubmit = handleSubmit;

  return (
    <Feature name="featureEnabler/advancedSearch">
      {(isEnabled) =>
        isEnabled ? (
          <>
            {showSpinner && (
              <div className="spinner">
                <Spinner
                  loading={showSpinner}
                  isUplifting={showSpinner}
                />
              </div>
            )}
            {upliftTimeout && (
              <div className="ccl__dpx-error">
                <div className="container">
                  <div className="ccl-c-error-message">
                    <div className="ccl-c-error-message__content">
                      <Text
                        content={`${labels['labels.common.oopsNotify']}`}
                      />
                    </div>
                    <div className="ccl-c-error-message__button-control">
                      <Button
                        icon="chevron-right"
                        text={`${labels['labels.common.goToDashboard']}`}
                        link={`/${country}/${lang}/home`}
                        data-analytics="view_dashboard_locate_patient"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!showSpinner && !upliftTimeout && (
              <>
                <div className="ccl__container">
                  <AdvancedSearch
                    config={config}
                    labels={labels}
                    data={data}
                    submitDisabled={submitDisabled}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {showProvisionalAlert && (
                  <div className="ccl__container">
                    <div
                      className="mt-2"
                      style={{ margin: 'auto', width: '40%' }}
                    >
                      <Alert
                        variant="error"
                        heading={provisionalAlertHeading}
                        actions={[
                          {
                            text: labels[
                              'labels.common.contactCustomerService'
                            ],
                            onClick: contactCustomerService,
                          },
                        ]}
                      >
                        {provisionalAlertText}
                      </Alert>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="ccl__container">
            <DisabledFeature
              title={labels['labels.common.locatePatient']}
              message={
                labels['labels.common.featureFlag.disabledText']
              }
            />
          </div>
        )
      }
    </Feature>
  );
};
export default LocatePatient;
